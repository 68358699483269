import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Pagination, Stack } from "@mui/material";
import Loading from "../Assests/Loading";

function Ebay() {
  const [selectedPageCount, setSelectedPageCount] = useState(100);
  const [ebaySalesReport, setebaySalesReport] = useState([]);
  const [productTotalCount, setProductTotlaCount] = useState("");
  const [filterCount, setFilterCount] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterShow, setFilterShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const totalPageCount = Math.ceil(productTotalCount / selectedPageCount);
  const totalPageFilterCount = Math.ceil(filterCount / selectedPageCount);
  const startIndex = (currentPage - 1) * selectedPageCount + 1;
  const endIndex = Math.min(currentPage * selectedPageCount, productTotalCount);
  const endFilterIndex = Math.min(currentPage * selectedPageCount, filterCount);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDropdownSelect = (value) => {
    setSelectedPageCount(value);
  };

  const download = () => {
    const data = {
      reportType: "eBaySalesReport",
      start: "",
      end: "",
      delimiter: ",",
      channel: "eBay",
    };
  
    axios
      .post("https://fpt.sensegroup.org/WebAPI/Sales/eBaySalesReportDownload", data, {
        responseType: 'blob' 
      })
      .then((res) => {
      
        const blob = new Blob([res.data]);

        const url = window.URL.createObjectURL(blob);
        // Create an anchor element
        const a = document.createElement('a');
        // Set the href attribute to the URL of the Blob
        a.href = url;
        // Specify the filename for the download
        a.download = 'Ebay_Sales_Report.csv';
        // Simulate a click on the anchor element to trigger the download
        a.click();
        // Clean up resources
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  const columns = [
    {
      field: "orderId",
      headerName: "Channel Refrence",
      flex: 1,
      minWidth: 120,
    },
    { field: "site", headerName: "Channel", flex: 1, minWidth: 120 },
    { field: "price", headerName: "Total Sale price", flex: 1, minWidth: 120 },
    {
      field: "eBayFee",
      headerName: "Transaction Fee",
      flex: 0.8,
      minWidth: 135,
    },
    {
      field: "paypalFee",
      headerName: "Managed Payment Fee",
      flex: 0.8,
      minWidth: 120,
    },
    { field: "currency", headerName: "Currency", flex: 0.8, minWidth: 120 },
    { field: "orderedOn", headerName: "OrderedOn", flex: 0.8, minWidth: 120 },
  ];

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    if (newValue === "") {
      setFilterShow(false);
    }
    setSearchValue(newValue);

    if (event.key === "Enter") {
      setLoading(true);

      const priceMonitorFilterData = {
        searchText: newValue,
        sortCol: "string",
        pageNbr: currentPage,
        pageSize: productTotalCount,
        direction: 0,
      };
      axios
        .post(
          "https://fpt.sensegroup.org/WebAPI/Sales/eBaySalesReports",
          priceMonitorFilterData
        )
        .then((res) => {
          setLoading(false);
          if (res.data.eBaySalesReports === null) {
            setebaySalesReport([]);
          } else {
            setebaySalesReport(res.data.eBaySalesReports);
          }
          setProductTotlaCount(res.data.totalCount);
          setFilterCount(res.data.filterCount);
          setFilterShow(true);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching data:", error);
        });
    }
  };
  const priceMonitorData = {
    searchText: searchValue,
    sortCol: "string",
    pageNbr: currentPage,
    pageSize: selectedPageCount,
    direction: 0,
  };

  useEffect(() => {
    ShippingProfiles();
  }, [currentPage, selectedPageCount]);

  const ShippingProfiles = () => {
    setLoading(true);
    axios
      .post(
        "https://fpt.sensegroup.org/WebAPI/Sales/eBaySalesReports",
        priceMonitorData
      )
      .then((res) => {
        setLoading(false);
        if (res.data.eBaySalesReports === null) {
          setebaySalesReport([]);
        } else {
          setebaySalesReport(res.data.eBaySalesReports);
        }
        setProductTotlaCount(res.data.totalCount);
        setFilterCount(res.data.filterCount);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div className="custom-container container-fluid mt-4">
      <div className="activelist-topbox d-flex justify-content-between">
        <h5>eBay Sales Report</h5>
        <div className="activelist-buttons">
          <Button variant="primary" className="me-2" onClick={download}>
            Download
          </Button>
        </div>
      </div>

      <div className="activelist-tablebox">
        <div
          className="activelist-topbox d-flex justify-content-between align-items-center mt-3"
          style={{ alignItems: "flex-end" }}
        >
          <div>
            {!filterShow ? (
              <span>
                Showing {startIndex} to {endIndex} of {productTotalCount}{" "}
                results
              </span>
            ) : (
              <span>
                Showing {startIndex} to {endFilterIndex} of {filterCount}{" "}
                results (filtered from {productTotalCount} total rows)
              </span>
            )}
          </div>
          <div class=" d-flex align-items-center  me-2">
            <label for="" class="form-label me-2 mt-2">
              Search:
            </label>
            <input
              type="text"
              class="form-control"
              name=""
              id=""
              placeholder=""
              // value={searchValue}
              onKeyDown={handleInputChange}
            />
          </div>
        </div>
      </div>

      <div className="data mt-3 ">
        <DataGrid
          className=""
          style={{ height: "calc(76vh - 120px)", width: "100%" }}
          rows={ebaySalesReport.map((row, index) => ({ ...row, id: index }))}
          columns={columns}
          rowHeight={40}
          hideFooter={true}
          loading={loading}
          slots={{
            loadingOverlay: Loading,
          }}
          disableRowSelectionOnClick
        />
        <div className="table-footer">
          <Row className="mt-3" style={{ fontSize: "13px" }}>
            <Col md={3} sm={6}>
              <div className=" d-flex  align-item-center ">
                <p className="me-2 mb-0 mt-1"> Show</p>
                <DropdownButton
                  id="dropdown-basic"
                  title={
                    <>
                      {selectedPageCount}
                      <span className="icon ms-1" id="arrorw">
                        &#9663;
                      </span>{" "}
                    </>
                  }
                  onSelect={handleDropdownSelect}
                >
                  <Dropdown.Item eventKey="100">100</Dropdown.Item>
                  <Dropdown.Item eventKey="500">500</Dropdown.Item>
                  <Dropdown.Item eventKey="1000">1000</Dropdown.Item>
                </DropdownButton>
                <p className="ms-2 mb-0 mt-1"> Rows</p>
              </div>
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="paginaton ">
                {!filterShow ? (
                  <Stack spacing={2} className=" mb-3">
                    <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={totalPageCount}
                      page={currentPage}
                      onChange={handlePageChange}
                      style={{
                        "& .Mui-selected": {
                          backgroundColor: "red !important",
                        },
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack spacing={2} className=" mb-3">
                    <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={totalPageFilterCount}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </Stack>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Ebay;
