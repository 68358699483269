
import React from 'react'

function Loading() {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{height:"100%", backgroundColor:"white"}}>
    <img src="images/load.gif" alt="load" height={100} width={100}></img>
  </div>
  )
}

export default Loading