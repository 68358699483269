import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notification, message } from "antd";

function EditPrice(props) {
  const location = useLocation();
  const [getPrice, setGetPrice] = useState({});
  const [priceMargin, setPriceMargin] = useState(0);
  const searchParams = new URLSearchParams(location.search);
  const itemId = searchParams.get("itemId");

  useEffect(() => {
    if (itemId !== null) {
      axios
        .get(
          `https://fpt.sensegroup.org/WebAPI/Inventory/GetMinMaxPrice?ItemId=${itemId}`
        )
        .then((res) => {
          setGetPrice(res.data);
        })
        .catch((error) => {
          console.error("Error occurred:", error);
          notification.error({
            message: "Error",
            description: "Somthing went wrong",
            duration: 1,
          });
        });
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setGetPrice((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleInputChangePriceMargin = (event) => {
    setPriceMargin(event.target.value);
  };
  function save() {
    const file = {
      listingId: getPrice.itemId,
      minPrice: getPrice.minPrice,
      maxPrice: getPrice.maxPrice,
      priceMargin: priceMargin,
    };
    axios
      .put(`https://fpt.sensegroup.org/WebAPI/Inventory/EditMinMaxPrice`, file)
      .then((res) => {
        if (res.data === "Saved Successfully!") {
          notification.success({
            message: "Success",
            description: "You have successfully Updated",
            duration: 1,
          });
        } else if (res.data === "Saved Faild!") {
          notification.warning({
            message: "Warning",
            description: "Updated Failed",
            duration: 1,
          });
        } else if (res.data === "Please enter the valid input values!") {
          notification.warning({
            message: "Warning",
            description: "Please enter the valid input values",
            duration: 1,
          });
        } else {
          notification.error({
            message: "Error",
            description: "Item id invalid",
            duration: 1,
          });
        }
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        notification.error({
          message: "Error",
          description: "Somthing went wrong",
          duration: 1,
        });
      });
  }

  return (
    <>
      <Container>
        <div className="mt-4 " style={{ borderBottom: "1px solid #EEE" }}>
          <h5 className="mb-3">Price Range for SKU : {getPrice.sku}</h5>
        </div>
        <div className="input-boxs">
          <div className="mt-3 mb-3">
            <Form.Label>Minimum price</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="minPrice"
              value={getPrice.minPrice || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className=" mb-3">
            <Form.Label>Maximum price</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="maxPrice"
              value={getPrice.maxPrice || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className=" mb-3">
            <Form.Label>Amount to beat competitor</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="priceMargin"
              value={priceMargin}
              onChange={handleInputChangePriceMargin}
            />
          </div>
          <div className="text-end mt-5">
            <Button variant="primary" onClick={save}>
              Save and Close
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
}

export default EditPrice;
