import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import SideBarToggle from "./Components/Layouts/SideBarToggle";
import LoginForm from "./Components/LoginFlow/LoginForm";
import SigninForm from "./Components/LoginFlow/SigninForm";
import Main from "./Components/Dasboard/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import Footer from "./Components/Layouts/Footer";
import EmailActivation from "./Components/LoginFlow/EmailActivation";
import SignupGuide from "./Components/LoginFlow/SignupGuide";
import ForgetPassword from "./Components/LoginFlow/ForgetPassword";
import ResetPassword from "./Components/LoginFlow/ResetPassword";
import PasswordGuide from "./Components/LoginFlow/PasswordGuide";
import ActiveListing from "./Components/InventoryPricing/Listing/ActiveListing";
import OutOfStockListing from "./Components/InventoryPricing/Listing/OutOfStockListing";
import MappedListing from "./Components/InventoryPricing/Listing/MappedListing";
import UnMappedListing from "./Components/InventoryPricing/Listing/UnMappedListing";
import ListingExpiryProtection from "./Components/InventoryPricing/Listing/ListingExpiryProtection";
import RepriceListing from "./Components/InventoryPricing/Listing/RepriceListing";
import TrackedItems from "./Components/InventoryPricing/OtherTabs/TrackedItems";
import RePrice from "./Components/InventoryPricing/OtherTabs/RePrice";
import EditPrice from "./Components/InventoryPricing/OtherTabs/EditPrice";
import Search from "./Components/InventoryPricing/OtherTabs/Search";
import MinpriceListing from "./Components/InventoryReports/MinpriceListing";
import MaxpriceListing from "./Components/InventoryReports/MaxpriceListing";
import ShippingProfiles from "./Components/InventoryPolicies/ShippingProfiles";
import PriceRange from "./Components/Import/PriceRange";
import CompetitorListing from "./Components/Import/CompetitorListing";
import RepriceApproval from "./Components/Import/RepriceApproval";
import Ebay from "./Components/Sales/Ebay";
import Amazon from "./Components/Sales/Amazon";
import FTPShedule from "./Components/Sales/FTPShedule";
import Magneto from "./Components/Sales/Magneto";
import ManualTrigger from "./Components/Sync/ManualTrigger";
import SatusHistory from "./Components/Sync/SatusHistory";
import SearchItems from "./Components/ProductSearch/SearchItems";
import AccountSetting from "./Components/Settings/AccountSetting";
import UserSetting from "./Components/Settings/UserSetting";
import ResultPage from "./Components/Sync/ResultPage";
import RepriceReport from "./Components/InventoryPricing/OtherTabs/RepriceReport";


function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/signup" element={<SigninForm />} />
       
    
        <Route element={<SideBarToggle />}>
          <Route path="/dashboard" element={<Main />} />
          
                        {/* Inventory Pricing */}
          <Route path="/reprice-listing" element={<RepriceListing />} />
          <Route path="/listing-expiry-protection" element={<ListingExpiryProtection />} />
          <Route path="/unmapped-listing" element={<UnMappedListing />} />
          <Route path="/mapped-listing" element={<MappedListing />} />
          <Route path="/outofstock-listing" element={<OutOfStockListing />} />
          <Route path="/active-listing" element={<ActiveListing />} />

                        {/* Inventory Reports */}
          <Route path="/minprice-listing" element={<MinpriceListing />} />
          <Route path="/maxprice-listing" element={<MaxpriceListing />} />

                        {/* Inventory Policies */}
          <Route path="/shipping-profiles" element={<ShippingProfiles />} />

                        {/* Imports */}
          <Route path="/pricerange" element={<PriceRange />} />
          <Route path="/competitor-listing" element={<CompetitorListing />} />
          <Route path="/reprice-approval" element={<RepriceApproval />} />

                        {/* Sales */}
          <Route path="/ebay" element={<Ebay />} />
          <Route path="/amazon" element={<Amazon />} />
          <Route path="/magneto" element={<Magneto/>} />
          <Route path="/ftp-schedule" element={<FTPShedule />} />

                         {/* Sync */}
           <Route path="/manual-trigger" element={<ManualTrigger />} />
           <Route path="/status-history" element={<SatusHistory />} />

                         {/* Product search */}
           <Route path="/search-items" element={<SearchItems />} />  

                         {/* Settings */}
           <Route path="/account-setting" element={<AccountSetting />} />            
           <Route path="/user-setting" element={<UserSetting />} />            
        </Route>


          <Route path="/emailActivation" element={<EmailActivation />} />
          <Route path="/tracked-items" element={<TrackedItems />} />
          <Route path="/price-audit" element={<ResultPage />} />
          <Route path="/edit-price" element={<EditPrice />} />
          <Route path="/reprice" element={<RePrice />} />
          <Route path="/search" element={<Search />} />
          <Route path="/reprice-report" element={<RepriceReport />} />
          <Route path="/SignupGuide" element={<SignupGuide />} />
          <Route path="/forgotPassword" element={<ForgetPassword />} />
          <Route path="/forgotPassword-success" element={<PasswordGuide />} />
          <Route path="/set-new-password" element={<ResetPassword />} />
       
        </Routes>
      </Router>

      <Footer />
    </div>
  );
}

export default App;
