import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { notification } from "antd";
import { Stack, Pagination } from "@mui/material";
import Loading from "../../Assests/Loading";

function RepriceListing() {
  const [selectedPageCount, setSelectedPageCount] = useState(100);
  const [repriceList, setRepriceList] = useState([]);
  const [productTotalCount, setProductTotlaCount] = useState("");
  const [filterCount, setFilterCount] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterShow, setFilterShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);

  const totalPageCount = Math.ceil(productTotalCount / selectedPageCount);
  const totalPageFilterCount = Math.ceil(filterCount / selectedPageCount);
  const startIndex = (currentPage - 1) * selectedPageCount + 1;
  const endIndex = Math.min(currentPage * selectedPageCount, productTotalCount);
  const endFilterIndex = Math.min(currentPage * selectedPageCount, filterCount);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDropdownSelect = (value) => {
    setSelectedPageCount(value);
  };
  const CustomLinkCell = (params) => {
    return (
      <a
        href={`https://www.ebay.co.uk/itm/${params.row.itemId}`}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        {params.row.itemId}
      </a>
    );
  };
  const start = () => {
    RePriceListing();
  };
  const Checkbox = (params) => {
    return (
      <input
        type="checkbox"
        className="ms-2 ps-2"
        checked={selectionModel.some(
          (item) => item.itemId === params.row.itemId
        )}
        onChange={(event) => handleCheckboxChange(event, params)}
      ></input>
    );
  };

  const handleCheckboxChange = (event, params) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectionModel((prevSelectionModel) => [
        ...prevSelectionModel,
        {
          itemId: params.row.itemId,
          sku: params.row.sku,
        },
      ]);
    } else {
      console.log("false");
      setSelectionModel((prevSelectionModel) =>
        prevSelectionModel.filter((item) => item.itemId !== params.row.itemId)
      );
    }
  };

  const handleCheckboxSelectallChange = (event, params) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      const allSelections = repriceList.map((row) => ({
        itemId: row.itemId,
        sku: row.sku,
      }));
      setSelectionModel(allSelections);
    } else {
      setSelectionModel([]);
    }
  };

  const columns = [
    {
      feild: "checkbox",
      width: 50,
      renderCell: Checkbox,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <input
          type="checkbox"
          className="ms-1"
          checked={selectionModel.length === repriceList.length}
          onChange={(event) => handleCheckboxSelectallChange(event, params)}
        ></input>
      ),
    },
    {
      field: "pictureURL",
      headerName: "Picture",
      width: 90,
      renderCell: renderImageCell,
    },
    { field: "sku", headerName: "SKU", flex: 0.3, minWidth: 170 },
    {
      field: "itemId",
      headerName: "ListingId",
      width: 120,
      renderCell: CustomLinkCell,
    },
    { field: "name", headerName: "Title", flex: 1, minWidth: 250 },
    { field: "price", headerName: "Price", width: 70 },
    { field: "cost", headerName: "Cost", width: 70 },
    { field: "minPrice", headerName: "Min Price", width: 60 },
    { field: "maxPrice", headerName: "Max Price", width: 60 },
    { field: "quantity", headerName: "Qty", width: 20 },
    {
      field: "tracked",
      headerName: "Tracked",
      width: 20,
      renderCell: (params) => (
        <Link
          to={`/tracked-items?sku=${params.row.sku}&itemId=${params.row.itemId}`}
          target="_blank"
          style={{ textDecoration: "none" }}
          rel="noopener noreferrer"
        >
          {params.row.tracked}
        </Link>
      ),
    },
    {
      field: "approved",
      headerName: "Appro ved",
      width: 20,
      renderCell: renderApprovedCell,
    },
    {
      field: "Action",
      headerName: "Report",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: EditItemButton,
    },
  ];

  function renderImageCell(params) {
    return (
      <img
        src={params.row.pictureURL}
        alt={`Picture for ${params.row.id}`}
        style={{ width: "40px", height: "auto" }}
      />
    );
  }

  function renderApprovedCell(params) {
    return (
      <>
        {params.value === true ? (
          <div className="ms-1">
            <img src="images/true1.png" width="20px" height="20px" alt="true" />
          </div>
        ) : (
          <div className="ms-1">
            <img
              src="images/false1.png"
              width="20px"
              height="20px"
              alt="false"
            />
          </div>
        )}
      </>
    );
  }

  function EditItemButton(params) {
    return (
      <div
        className="d-flex ms-3"
        style={{ textDecoration: "none !important" }}
      >
      
        <a href={`/reprice-report?itemId=${params.row.itemId}`}  target="_blank" style={{ textDecoration: "none" }}>
          View
        </a>
        {/* <a href=""  style={{ textDecoration: "none" }}> */}
          {/* View
        </a> */}
      </div>
    );
  }

  const unApprove = () => {
    const UA = {
      deleteAction: "Delete",
      unApprovals: selectionModel,
    };
    console.log(selectionModel);
    if (selectionModel.length > 0) {
      axios
        .put(
          "https://fpt.sensegroup.org/WebAPI/ApprovedToReprice/UnApproveToReprice",
          UA
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.startsWith("SKUs added successfully")) {
            notification.success({
              message: "Success",
              description: res.data,
              duration: 0.5,
            });
            setSelectionModel([]);
            RePriceListing();
          } else {
            notification.error({
              message: "Error",
              description: "UnApproved Failed",
              duration: 0.5,
            });
            setSelectionModel([]);
          }
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description: "Somthing went wrong",
            duration: 0.5,
          });
          setSelectionModel([]);
        });
    } else {
      notification.warning({
        message: "Warning",
        description: "Please select minimum one checkbox",
        duration: 0.5,
      });
    }
  };

  const handleInputChange = (event) => {
    
    const newValue = event.target.value;
    if (newValue === "") {
      setFilterShow(false);
    }
    setSearchValue(newValue);

    if(event.key === "Enter"){
      setLoading(true);
    
    const priceMonitorFilterData = {
      searchText: newValue,
      sortCol: "string",
      pageNbr: currentPage,
      pageSize: productTotalCount,
      direction: 0,
    };
    axios
      .post(
        "https://fpt.sensegroup.org/WebAPI/Inventory/RepriceListing",
        priceMonitorFilterData
      )
      .then((res) => {
        setLoading(false);
        if (res.data.products === null) {
          setRepriceList([]);
        } else {
          setRepriceList(res.data.products);
        }
        setProductTotlaCount(res.data.totalCount);
        setFilterCount(res.data.filterCount);
        setFilterShow(true);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
    }
  };
  const priceMonitorData = {
    searchText: searchValue,
    sortCol: "string",
    pageNbr: currentPage,
    pageSize: selectedPageCount,
    direction: 0,
  };

  useEffect(() => {
    RePriceListing();
  }, [currentPage, selectedPageCount]);

  const RePriceListing = () => {
    setLoading(true);
    axios
      .post(
        "https://fpt.sensegroup.org/WebAPI/Inventory/RepriceListing",
        priceMonitorData
      )
      .then((res) => {
        setLoading(false);
        if (res.data.products === null) {
          setRepriceList([]);
        } else {
          setRepriceList(res.data.products);
        }

        setProductTotlaCount(res.data.totalCount);
        setFilterCount(res.data.filterCount);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const exportAll = async () => {
    try {
      const downloadUrl = `https://fpt.sensegroup.org/WebAPI/ExportAll?name=Reprice`;
      const response = await fetch(downloadUrl);

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      // Create a link and trigger the download
      const link = document.createElement("a");
      link.href = blobUrl;
      const currentDateTimeUTC = new Date()
        .toISOString()
        .replace(/[-:T]/g, "_")
        .replace(/\..+/, "")
        .replace(/_/g, " ")
        .substring(0, 19);

      link.download = `RepriceListing_${currentDateTimeUTC}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div className="custom-container container-fluid mt-2">
      <div className="activelist-topbox d-flex justify-content-between">
        <h5 className="mt-2">Reprice Listing </h5>
        <div className="activelist-buttons">
          <Button variant="primary" className="me-2 mt-2" onClick={unApprove}>
            UnApprove to Reprice
          </Button>

          <Button variant="primary" className="me-2 mt-2" onClick={exportAll}>
            Export All
          </Button>

          <Button variant="primary" className="me-2 mt-2" onClick={start}>
            Refresh
          </Button>
        </div>
      </div>
      <div className="activelist-tablebox">
        <div
          className="activelist-topbox d-flex justify-content-between align-items-center mt-3"
          style={{ alignItems: "flex-end" }}
        >
          <div>
            {!filterShow ? (
              <span>
                Showing {startIndex} to {endIndex} of {productTotalCount}{" "}
                results
              </span>
            ) : (
              <span>
                Showing {startIndex} to {endFilterIndex} of {filterCount}{" "}
                results (filtered from {productTotalCount} total rows)
              </span>
            )}
          </div>
          <div class=" d-flex align-items-center  me-2">
            <label for="" class="form-label me-2 mt-2">
              Search:
            </label>
            <input
              type="text"
              class="form-control"
              name=""
              id=""
              placeholder=""
              // value={searchValue}
              onKeyDown={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className="data mt-3 ">
        <DataGrid
          className=""
          style={{ height: "calc(76vh - 120px)", width: "100%" }}
          rows={repriceList}
          columns={columns}
          hideFooter={true}
          loading={loading}
          disableRowSelectionOnClick
          slots={{
            loadingOverlay: Loading,
          }}
          rowHeight={40}
        />
        <div className="table-footer">
          <Row className="mt-3" style={{ fontSize: "13px" }}>
            <Col md={3} sm={6}>
              <div className=" d-flex  align-item-center ">
                <p className="me-2 mb-0 mt-1"> Show</p>
                <DropdownButton
                  id="dropdown-basic"
                  title={
                    <>
                      {selectedPageCount}
                      <span className="icon ms-1" id="arrorw">
                        &#9663;
                      </span>{" "}
                    </>
                  }
                  onSelect={handleDropdownSelect}
                >
                  <Dropdown.Item eventKey="100">100</Dropdown.Item>
                  <Dropdown.Item eventKey="500">500</Dropdown.Item>
                  <Dropdown.Item eventKey="1000">1000</Dropdown.Item>
                </DropdownButton>
                <p className="ms-2 mb-0 mt-1"> Rows</p>
              </div>
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="paginaton ">
                {!filterShow ? (
                  <Stack spacing={2} className=" mb-3">
                    <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={totalPageCount}
                      page={currentPage}
                      onChange={handlePageChange}
                      style={{
                        "& .Mui-selected": {
                          backgroundColor: "red !important",
                        },
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack spacing={2} className=" mb-3">
                    <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={totalPageFilterCount}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </Stack>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default RepriceListing;
