import { notification } from 'antd';
import axios from 'axios';
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

function CompetitorListing() {
  const handleFileChange = (event) => {
    const File = event.target.files[0];
    const formData = new FormData();
    formData.append("source", "PriceRange");
    formData.append("formFile", File);

    axios
      .post("https://fpt.sensegroup.org/WebAPI/Import?source=TrackingItems" ,formData)
      .then((res) => {
        if (res.data === "Import process completed successfully.") {
          notification.success({
            message: "Success",
            description: "You have successfully Imported",
            duration: 1,
          });
        } else {
          notification.error({
            message: "Error",
            description: "Somthing went wrong",
            duration: 1,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        notification.error({
          message: "Error",
          description: "Somthing went wrong",
          duration: 1,
        });
      });
  };
  return (
    <div className="custom-container container-fluid mt-4">
    <h5>Import - Competitor Listing</h5>
    <div className="import-container">
      <div className="import-box">
        <Row>
          <Col md={12} className="custom-file-button fileSelect">
            <Form.Control type="file" id="file-upload"     onChange={handleFileChange} />
          </Col>
        </Row>
        <Row className="import mt-5">
          <Col md={12}>
            <h6>Instruction</h6>
            <ul>
              <li>Import file should be Tab delimited text or CSV file format</li>
              <li>Example file header: SKU ItemId1 ItemId2 ItemId3 ItemId4 ItemId5</li>
              <li>
              The succcess response includes the total number of successfully inserted records & duplicate records
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  </div>
  )
}

export default CompetitorListing