import React, { useEffect, useState } from "react";
import { NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import menu from "./menu";
import menuBottom from "./menu-bottom.json";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

function Layout() {
  console.log(menu);
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);
  const [activeSubmenuItem, setActiveSubmenuItem] = useState(null);

  useEffect(() => {
    const storedIndex = localStorage.getItem("openSubMenuIndex");
    const storedActiveSubmenuItem = localStorage.getItem("activeSubmenuItem");
    if (storedIndex !== null) {
      setOpenSubMenuIndex(parseInt(storedIndex));
    }
    if (storedActiveSubmenuItem !== null) {
      setActiveSubmenuItem(storedActiveSubmenuItem);
    }
  }, []);

  const handleSubMenuClick = (index) => {
    setOpenSubMenuIndex((prevIndex) => (prevIndex === index ? null : index));
    localStorage.setItem("openSubMenuIndex", index);
  };

  const handleSubMenuItemClick = (submenuTo) => {
    setActiveSubmenuItem(submenuTo);
    localStorage.setItem("activeSubmenuItem", submenuTo);
  };
  return (
    <div className="layout">
      <div className="side">
        <div className="header-wrapper">
          <div className="brand-logo">
            <h4 className="logolarge text-center">Tool Sense</h4>
          </div>
        </div>
        <nav>
          {menu.map((menuItem, index) => (
            <ul className="menus" key={index}>
              <li
                className={`menu-items ${
                  window.location.pathname === menuItem.to ||
                  (menuItem.submenuList &&
                    menuItem.submenuList.some(
                      (submenu) => window.location.pathname === submenu.to
                    ))
                    ? "active"
                    : ""
                }`}
                onClick={() => handleSubMenuClick(index)}
              >
                <Link to={menuItem.to}>
                  <span className="ms-2">
                    <p className="p-0 m-0 mx-1 icon-items">{menuItem.icon}</p>{" "}
                    {menuItem.name}
                  </span>
                </Link>
                <span>
                  {menuItem.submenuList !== null && (
                    <img
                      src="images/down-arrow.png"
                      className={`arrow  ${
                        openSubMenuIndex === index ? "rotate-180" : ""
                      }`}
                      width="25"
                      height="25"
                      alt="menu-icon"
                    />
                  )}
                </span>
              </li>

              {openSubMenuIndex === index &&
                menuItem.submenuList &&
                menuItem.submenuList.map((submenu, subIndex) => (
                  <ul className="submenu-dropdown mt-2" key={subIndex}>
                    <Link
                      to={submenu.to}
                      style={{ textDecoration: "none", color: "white" }}
                      onClick={() => handleSubMenuItemClick(submenu.to)} // Handle submenu item click
                    >
                      <li
                        className={`menu-items ${
                          window.location.pathname === submenu.to ||
                          activeSubmenuItem === submenu.to // Check if submenu item is active
                            ? "active"
                            : ""
                        }`}
                      >
                        <span style={{ fontSize: "13px" }}>
                          {" "}
                          <NavigateNextIcon />
                          {submenu.name}
                        </span>
                      </li>
                    </Link>
                  </ul>
                ))}
            </ul>
          ))}
          {/* 
          <ul className="menus bottom">
            <div className="hr-line-container"></div>
            <li className="menu-items">
              <Link>
                <img
                  src="images\7thicon.svg"
                  alt="drop icon"
                  className="white-icon"
                />
                <span className="ms-2">Settings</span>
              </Link>
            </li>
          </ul> */}
        </nav>
      </div>
    </div>
  );
}

export default Layout;
