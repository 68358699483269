import { DataGrid } from "@mui/x-data-grid";
import { notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Loading from "../Assests/Loading";

function SearchItems() {
  const [searchItems, setSearchItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addSearchDetails, setAddSearchDeatails] = useState({
    id: 0,
    keyWords: "",
    url: "",
  });

  const columns = [
    { field: "keyword", headerName: "Name", flex: 0.3, minWidth: 120 },
    {
      field: "searchUrl",
      headerName: "Search Url",
      flex: 1,
      minWidth: 450,
      renderCell: (params) => {
        return (
          <>
            <a href={params.row.searchUrl} target="_blank">
              {params.row.searchUrl}
            </a>
          </>
        );
      },
    },
    { field: "createdOn", headerName: "Created On", flex: 0.3, minWidth: 150 },
    {
      field: "isActive",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        return (
          <div className="ms-4" style={{ color: "#0d6efd" }}>
            {params.row.isActive === true ? (
              <div onClick={() => Update(params.row.id)}>InActive</div>
            ) : (
              <div onClick={() => Update(params.row.id)}>ReActive</div>
            )}
          </div>
        );
      },
    },
  ];

  const Update = (id) => {
    console.log(id);

    axios
      .put("https://fpt.sensegroup.org/WebAPI/ProductSearch/UpdateSearchItem", {
        id: id,
      })
      .then((res) => {
        if (res.data === "") {
          notification.success({
            message: "Successfully updated",
            description: "You have successfully updated",
            duration: 1,
          });
        } else {
          notification.error({
            message: "Error",
            description: "Somthing went wrong",
            duration: 1,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        notification.error({
          message: "Error",
          description: "Somthing went wrong",
          duration: 1,
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        "https://fpt.sensegroup.org/WebAPI/ProductSearch/GetProductSearchList"
      )
      .then((res) => {
        setSearchItems(res.data.productLists);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleAddSearchChange = (e) => {
    const { name, value } = e.target;
    setAddSearchDeatails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const addSearch = () => {
    console.log(addSearchDetails);
    axios
      .post(
        "https://fpt.sensegroup.org/WebAPI/ProductSearch/AddSearchItem",
        addSearchDetails
      )
      .then((res) => {
        if (res.data === "Successfully added") {
          notification.success({
            message: "Success",
            description: "You have successfully added",
            duration: 1,
          });
        } else if (res.data === "Please Enter KewWord and Url") {
          notification.success({
            message: "warning",
            description: "Please enter Name and Url",
            duration: 1,
          });
        } else {
          notification.error({
            message: "Error",
            description: "Somthing went wrong",
            duration: 1,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        notification.error({
          message: "Error",
          description: "Somthing went wrong",
          duration: 1,
        });
      });
  };

  return (
    <div className="custom-container container-fluid mt-4">
      <div className="top-content pb-2">
        <h5>Product Search</h5>
      </div>
      <div className="input-content mt-3">
        <Row>
          <Col>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              vlaue={addSearchDetails.keyWords}
              name="keyWords"
              onChange={handleAddSearchChange}
            />
          </Col>
          <Col>
            <Form.Label>Search Url</Form.Label>
            <Form.Control
              type="text"
              vlaue={addSearchDetails.url}
              name="url"
              placeholder=""
              onChange={handleAddSearchChange}
            />
          </Col>
        </Row>
        <div className="text-end mt-3">
          <Button onClick={addSearch}>Save</Button>
        </div>
      </div>
      <div className="data-grid mt-3">
        <DataGrid
          style={{ width: "100%", height: "315px" }}
          columns={columns}
          rows={searchItems}
          hideFooter
          disableRowSelectionOnClick
          loading={loading}
          slots={{
            loadingOverlay: Loading,
          }}
        />
      </div>
      <div className="mt-3 mb-5">
        <Button>Start Search</Button>
      </div>
    </div>
  );
}

export default SearchItems;
