import React, { useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { notification, message } from "antd";

const LoginForm = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [redirectToMain, setRedirectToMain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, setLogin] = useState("");
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      setLoading(false);
      return;
    }

    setLoading(true);
    if (email === "Steve" && password === "Colindale123") {
      localStorage.setItem("username" , "Steve")
      navigate("/dashboard");
      notification.success({
        message: "Success",
        description: "You have successfully logged in",
        duration: 0.5,
      });
    } 
    else if (email === "Mohan" && password === "Price$123") {
      localStorage.setItem("username" , "Mohan")
      navigate("/dashboard");
      notification.success({
        message: "Success",
        description: "You have successfully logged in",
        duration: 0.5,
      });
    } 
    else if (email === "Ann" && password === "zh5Gx9[vM~F@Cn!c") {
      localStorage.setItem("username" , "Ann")
      navigate("/dashboard");
      notification.success({
        message: "Success",
        description: "You have successfully logged in",
        duration: 0.5,
      });
    } 
    else if (email === "Suzanne" && password === "zh5Gx9[vM~F@Cn!c") {
      localStorage.setItem("username" , "Suzanne")
      navigate("/dashboard");
      notification.success({
        message: "Success",
        description: "You have successfully logged in",
        duration: 0.5,
      });
    } 
    else {
      setLoading(false);
      notification.warning({
        message: "Warning",
        description: "The user does not exist",
        duration: 0.5,
      });
    }
    //  else{
    //     axios
    //       .get(
    //         `https://datastormapi.azurewebsites.net/User?Email=${email}&Password=${password}`,
    //         {
    //           email: email,
    //           password: password,
    //         }
    //       )
    //       .then((res) => {
    //         setLogin(res.data);
    //         console.log(res.data);

    //         if (
    //           res.data.userStatus ===
    //           "Please Activate your Account Email Verification is Pending"
    //         ) {
    //           setLoading(false);
    //           navigate("/emailActivation");
    //         } else if (res.data.userStatus === "Valid User") {
    //           const adminId = res.data.userInfo.id;
    //           localStorage.setItem("adminId", adminId);
    //           const firstName = res.data.userInfo.firstName;
    //           localStorage.setItem("firstName", firstName);
    //           const lastName = res.data.userInfo.lastName;
    //           localStorage.setItem("lastName", lastName);
    //           const roleId = res.data.userInfo.roleId;
    //           localStorage.setItem("roleId", roleId);
    //           const vendor = res.data.userInfo.vendors;
    //           localStorage.setItem("vendor", JSON.stringify(vendor));

    //           const permissionDto = res.data.userInfo.permissionDto;
    //           localStorage.setItem(
    //             "permissionDto",
    //             JSON.stringify(permissionDto)
    //           );
    //           console.log(permissionDto);
    //           notification.success({
    //             message: "Success",
    //             description: "You have successfully logged in",
    //           });
    //           navigate("/dashboard");

    //         }
    //         else if(  res.data.userStatus ==="If you are Login for the First time ,Please Reset Your Password"){
    //           setLoading(false);
    //           notification.warning({
    //             message: "Warning",
    //             description: "If you are logging in for the first time, please reset your password",
    //           });

    //         }
    //         else if( res.data.userStatus ==="User not exist in the database"){
    //           setLoading(false);
    //           notification.warning({
    //             message: "Warning",
    //             description: "The user does not exist",
    //           });
    //         }

    //         else {

    //           setLoading(false);
    //           notification.error({
    //             message: "Error",
    //             description: "Somthing Wrong!",
    //           });
    //         }
    //       })
    //       .catch((error) => {
    //         console.error("Error fetching data:", error);
    //         setLoading(false);
    //       });
    //     }
  };

  return (
    <div className="login-box mt-5 mb-5">
      <div className="contanier-type1">
        <div className="box-body">
        <div className="white-box">
        <div className="brand-logo text-center">
          <img src="images/logotool.png" alt="logo" className="logolarge img-fluid" />
        </div>
        <Form
          onSubmit={handleSubmit}
          noValidate
          validated={validated}
          
        >
          <Form.Group className="mb-3" controlId="validationCustom01">
            <Form.Label>User Name</Form.Label>
            <Form.Control
              type="text"
              style={{ height: "50px" }}
              
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please fill the user name.
            </Form.Control.Feedback>
          </Form.Group>

          <div className="mb-0 password-cont">
            <Form.Group className="mb-4" controlId="validationCustom02">
            <Form.Label>Password</Form.Label>
              <Form.Control
                type={isPasswordVisible ? "text" : "password"}
                
                style={{ height: "50px" }}
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                className="show-password"
                onClick={togglePasswordVisibility}
              >
                <FontAwesomeIcon
                  icon={isPasswordVisible ? faEye : faEyeSlash}
                />
              </span>
              <Form.Control.Feedback type="invalid">
                Please fill the password.
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          {/* <div className="mb-4 mt-3">
            <div className="custom-control custom-checkbox">
              <Form.Check
                type="checkbox"
                className="custom-control-input login-checkbox"
                id="customCheck1"
                label="Remember me"
              />
            </div>
          </div> */}
          <div className="d-grid">
            <Button
              className="btn-primary-login"
              type="submit"
              style={{ height: "50px", fontSize: "16px" }}
            >
              {!loading ? (
                "Log in"
              ) : (
                <div className="spinner-box">
                  <div className="pulse-container">
                    <div className="pulse-bubble pulse-bubble-1"></div>
                    <div className="pulse-bubble pulse-bubble-2"></div>
                    <div className="pulse-bubble pulse-bubble-3"></div>
                  </div>
                </div>
              )}
            </Button>
          </div>
          {/* <div className="new-create-account">
            <p className="text-center">
              No account? <a href="/signup">Sign Up</a>
            </p>
          </div> */}
        </Form>
      </div>
      </div>
      </div>
    </div>
  );
};

export default LoginForm;
