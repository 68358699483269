import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import Loading from "../../Assests/Loading";
ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement 
)

function RepriceReport() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const itemId = searchParams.get("itemId");
  const [xKey, setXkey] = useState("Month");
  const [priceHistory, setPriceHistory] = useState([]);
  const [quantityHistory, setQuantityHistory] = useState([]);
  const[loading ,setloading] =useState(false)

  useEffect(() => {
    setloading(true)
    const data = {
      itemId: itemId,
      xKey: xKey,
    };
    if (itemId) {
      axios
        .post(
          "https://fpt.sensegroup.org/WebAPI/Inventory/PriceAudit/Report",
          data
        )
        .then((res) => {
          setloading(false)
          const price = JSON.parse(res.data.priceJsonData); ;
          setPriceHistory(price === null ?[]:price);
          const Qty = JSON.parse(res.data.qtyJsonData);
          setQuantityHistory(Qty === null ?[]:Qty);
        })
        .catch((error) => {
          console.log(error);
          setloading(false)
        });
    }
  }, [xKey]);

  const handleradiochange = (e) => {
    setXkey(e.target.value);
  };

  const data = {
    labels: priceHistory.map((item) => item.CreatedOn),
    datasets: [
      {
        label: "primetools",
        data: priceHistory.map((item) => item.primetools),
        backgroundColor: "rgba(54, 162, 235, 0.2)", 
        borderColor: "rgba(54, 162, 235, 1)", 
        borderWidth: 1,
      },
    ],
  };
  const Qtydata = {
    labels: quantityHistory.map((item) => item.CreatedOn),
    datasets: [
      {
        label: "primetools",
        data: quantityHistory.map((item) => item.primetools),
        backgroundColor: "rgba(54, 162, 235, 0.2)", 
        borderColor: "rgba(54, 162, 235, 1)", 
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: "day",
          },
        },
      ],
    },
  };

  return (
    <Container>
      <div className="mt-4 " style={{ borderBottom: "1px solid #EEE" }}>
        <h5 className="mb-3">Reprice Report</h5>
      </div>
      <Row className="mt-3">
        <div className=" d-flex flex-wrap">
          <div>
            <Form.Check
              label="Today"
              type="radio"
              className="user-select-none me-3"
              id="radio-button-id"
              name="radioGroup"
              defaultChecked={true}
              value={"Time"}
              onChange={handleradiochange}
            />
          </div>
          <div>
            <Form.Check
              label="Last 7 days"
              type="radio"
              name="radioGroup"
              className="user-select-none me-3"
              id="radio-button-id2"
              value={"Week"}
              onChange={handleradiochange}
            />
          </div>
          <div>
            <Form.Check
              label="Last 30 days"
              type="radio"
              name="radioGroup"
              className=" me-3"
              id="radio-button-id3"
              value={"Month"}
              onChange={handleradiochange}
            />
          </div>
        </div>
      </Row>
{!loading ? (
  <div>
      <Row className="mt-3">
        <h6 style={{ fontWeight: "600" }}>Price History</h6>
        {priceHistory.length > 0 ? (
          <div className="mt-3">
            <Line data={data} options={options}style={{ width: "100%",maxHeight: "350px", }} />
          </div>
        ):(
          <div style={{height:"200px"}} className="d-flex justify-content-center align-items-center">
          <div>No data</div>
        </div>
        )}
      </Row>
      <Row className="mt-3 pb-5 mb-5">
        <h6 style={{ fontWeight: "600" }}>Quantity History</h6>
        {quantityHistory.length > 0 ? (
          <div className="mt-3">
            <Line data={Qtydata} options={options}style={{ width: "100%", maxHeight: "350px", }} />
          </div>
        ):(
          <div style={{height:"200px"}} className="d-flex justify-content-center align-items-center">
          <div>No data</div>
        </div>
        )}
      </Row>
      </div>
      ):(
        <div style={{height:"70vh"}}>
          <Loading/>
        </div>
      )}
    </Container>
  );
}

export default RepriceReport;
