import React from "react";

function Footer() {
  return (
    <div>
      <footer class="footer">
        <span>
          Copyright &copy; 2024<a href="#"> Tool Sense &reg;</a>.
        </span>
      </footer>
    </div>
  );
}

export default Footer;
