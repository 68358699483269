import React, { useEffect, useState } from "react";
import "./sales.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { TimePicker, notification } from "antd";
import dayjs from "dayjs";

function FTPShedule() {
  const [ftpDetails, setFtpDetails] = useState("");
  const [Time, setTime] = useState("");
  const [ScheduleSelectedValue, setScheduleSelectedValue] =
    useState("EveryDay");

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setScheduleSelectedValue(selectedValue);
    setFtpDetails((prevState) => ({
      ...prevState,
      schedule: {
        ...prevState.schedule,
        scheduleType: selectedValue,
      },
    }));
  };

  useEffect(() => {
    axios
      .get("https://fpt.sensegroup.org/WebAPI/Sales/GetFTPSchedule")
      .then((res) => {
        setFtpDetails(res.data);
        setFtpDetails((prevState) => ({
          ...prevState,
          schedule: {
            ...prevState.schedule,
            daysOfWeek: [],
          },
        }));
        setTime(res.data.sendTime);
        console.log(Time);
      })

      .catch((error) => {
        console.log(error);
      });
  }, [Time]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFtpDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const save = () => {
    console.log(ftpDetails);
    axios
      .put(
        "https://fpt.sensegroup.org/WebAPI/Sales/UpdateFTPSchedule",
        ftpDetails
      )
      .then((res) => {
        console.log(res.data);
        notification.success({
          message: "Success",
          description: "You have successfully Updated",
          duration: 1,
        });
      })
      .catch((error) => {
        console.log(error);
        notification.error({
          message: "Error",
          description: "Somthing went wrong",
          duration: 1,
        });
      });
  };

  const onChange = (timeString) => {
    const formattedTime = dayjs(timeString).format("hh:mm A");
    setFtpDetails((prevState) => ({
      ...prevState,
      sendTime: formattedTime,
    }));
  };
  return (
    <div className="custom-container container-fluid mt-4">
      <div className="top-content pb-2">
        <h5>File Schedule</h5>
      </div>
      <div className="input-content">
        <Row className="mt-3">
          <Col>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={ftpDetails.name || ""}
              onChange={handleInputChange}
            />
          </Col>
          <Col>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Description <span className="text-bg-light">Optional</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={ftpDetails.description || ""}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Form.Label>Report Type</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="reportType"
              onChange={handleInputChange}
            >
              <option value="SalesReport">Export Sales Report</option>
            </Form.Select>
          </Col>
          <Col>
            <Form.Label>Schedule</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={ScheduleSelectedValue}
              onChange={handleChange}
            >
              <option value="EveryDay">Every day</option>
              <option value="DaysOfWeek">Selected days of the week</option>
              <option value="FirstDayOfMonth">First day of each month</option>
              <option value="LastDayOfMonth">Last day of each month</option>
              <option value="DayOfMonth">A specific day in each month</option>
            </Form.Select>
          </Col>
        </Row>
        <Row className="mt-4">
          {Time !== "" && (
            <Col className="d-flex flex-column">
              <Form.Label>Time to Export</Form.Label>
              <TimePicker
                style={{ height: "40px" }}
                onChange={onChange}
                defaultValue={dayjs(Time, "HH:mm A")}
                format={"HH:mm A"}
              />
            </Col>
          )}
          <Col></Col>
        </Row>
      </div>
      <div className="save-content mt-3 d-flex justify-content-end">
        <Button variant="primary" className="me-2" onClick={save}>
          Save
        </Button>
      </div>
    </div>
  );
}

export default FTPShedule;
