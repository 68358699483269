import { notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

function AccountSetting() {
  const [accountDetails, setAccountDetails] = useState("");

  useEffect(() => {
    axios
      .get("https://fpt.sensegroup.org/WebAPI/Setting/AccountSetting")
      .then((res) => {
        setAccountDetails(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name.startsWith("ftpDetail.")) {
      setAccountDetails((prevState) => ({
        ...prevState,
        ftpDetail: {
          ...prevState.ftpDetail,
          [name.split(".")[1]]: value,
        },
      }));
    } else if (name.startsWith("smtpDetail.")) {
      setAccountDetails((prevState) => ({
        ...prevState,
        smtpDetail: {
          ...prevState.smtpDetail,
          [name.split(".")[1]]: value,
        },
      }));
    } else {
      setAccountDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const save = () => {
    console.log(accountDetails);
    axios
      .post(
        "https://fpt.sensegroup.org/WebAPI/Setting/UpdateAccountSetting",
        accountDetails
      )
      .then((res) => {
        if (res.data === "Saved Successfully!") {
          notification.success({
            message: "Success",
            description: "You have successfully Updated",
            duration: 1,
          });
        } else if (res.data === "Save Failed!") {
          notification.error({
            message: "Error",
            description: "Update failed",
            duration: 1,
          });
        } else {
          notification.error({
            message: "Error",
            description: "Somthing went wrong",
            duration: 1,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        notification.error({
          message: "Error",
          description: "Somthing went wrong",
          duration: 1,
        });
      });
  };

  return (
    <div className="custom-container container-fluid mt-4">
      <Row>
        <div className="top-content pb-2">
          <h5>Price</h5>
        </div>
        <Col md={6} sm={6} className="mt-2">
          <Form.Label>Amount to beat competitor</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            value={accountDetails.priceMargin}
            name="priceMargin"
            onChange={handleInputChange}
          />
        </Col>
        <Col className="d-flex  align-items-end">
          <Form.Check
            label="In Vacation"
            className="user-select-none"
            id="checkbox-id"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <div className="top-content pb-2">
          <h5>Marketplace Account Details</h5>
        </div>
        <Row>
          <Col md={6} sm={6} className="mt-2">
            <Form.Label>Amazon MerchantID</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="merchantID"
              onChange={handleInputChange}
              value={accountDetails.merchantID}
            />
          </Col>
          <Col md={6} sm={6} className="mt-2">
            <Form.Label>Amazon MarketplaceID</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={accountDetails.marketplaceID}
              name="marketplaceID"
              onChange={handleInputChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} className="mt-2">
            <Form.Label>Amazon AWSAccessKeyID</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={accountDetails.awsAccessKeyID}
              name="awsAccessKeyID"
              onChange={handleInputChange}
            />
          </Col>
          <Col md={6} sm={6} className="mt-2">
            <Form.Label>Amazon SecretKey</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={accountDetails.secretKey}
              name="secretKey"
              onChange={handleInputChange}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-2">
            <Form.Label>EBay Token</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={accountDetails.eBayToken}
              name="eBayToken"
              onChange={handleInputChange}
            />
          </Col>
        </Row>
      </Row>
      <Row className="mt-4">
        <div className="top-content pb-2">
          <h5>FTP Account Details</h5>
        </div>
        <Row>
          <Col md={6} sm={6} className="mt-2">
            <Form.Label>Server</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="ftpDetail.server"
              onChange={handleInputChange}
              value={accountDetails?.ftpDetail?.server}
            />
          </Col>
          <Col md={6} sm={6} className="mt-2">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="ftpDetail.userName"
              onChange={handleInputChange}
              value={accountDetails?.ftpDetail?.userName}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} className="mt-2">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="ftpDetail.password"
              onChange={handleInputChange}
              value={accountDetails?.ftpDetail?.password}
            />
          </Col>
          <Col md={6} sm={6} className="mt-2">
            <Form.Label>Folder Location</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="ftpDetail.path"
              onChange={handleInputChange}
              value={accountDetails?.ftpDetail?.path}
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} className="mt-2">
            <Form.Label>File Extension</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="ftpDetail.fileExtension"
              onChange={handleInputChange}
              value={accountDetails?.ftpDetail?.fileExtension}
            >
              <option value="CSV">CSV</option>
              <option value="TXT">TXT</option>
            </Form.Select>
          </Col>
        </Row>
      </Row>
      <Row className="mt-4">
        <div className="top-content pb-2">
          <h5>SMTP Account Details</h5>
        </div>
        <Row>
          <Col md={6} sm={6} className="mt-2">
            <Form.Label>Server</Form.Label>
            <Form.Control
              type="text"
              placeholder=" "
              name="smtpDetail.server"
              onChange={handleInputChange}
              value={accountDetails?.smtpDetail?.server}
            />
          </Col>
          <Col md={6} sm={6} className="mt-2">
            <Form.Label>Port</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="smtpDetail.port"
              onChange={handleInputChange}
              value={accountDetails?.smtpDetail?.port}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} className="mt-2">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="smtpDetail.userName"
              onChange={handleInputChange}
              value={accountDetails?.smtpDetail?.userName}
            />
          </Col>
          <Col md={6} sm={6} className="mt-2">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              name="smtpDetail.password"
              onChange={handleInputChange}
              value={accountDetails?.smtpDetail?.password}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6} className="mt-2">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              onChange={handleInputChange}
              value={accountDetails.email}
              placeholder="Example : yourmail@domain.com"
            />
          </Col>
        </Row>
      </Row>
      <Row>
        <Col className="text-end mt-4 mb-5">
          <Button onClick={save}>Save</Button>
        </Col>
      </Row>
    </div>
  );
}

export default AccountSetting;
