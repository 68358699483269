import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Loading from "../../Assests/Loading";

function Search() {
  const location = useLocation();
  const [searchPageData, setSearchPageData] = useState({});
  const [price, setPrice] = useState(0);
  const [title, setTitle] = useState("");

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const itemId = searchParams.get("itemId");
  const sku = searchParams.get("sku");

  const columns = [
    { field: "itemId", headerName: "ListingId", width: 120 },
    { field: "prictureURL", headerName: "picture", renderCell: imageRender },
    { field: "title", headerName: "Title", flex: 1, minWidth: 200 },
    { field: "price", headerName: "Price" },
    { field: "condition", headerName: "Condition" },
    { field: "qtySold", headerName: "Quantity Sold" },
    { field: "qtyAvailable", headerName: "Quantity Available" },
    {
      field: "sellername",
      headerName: "Seller Details",
      flex: 0.4,
      minWidth: 150,
    },
    { field: "shippingPrice", headerName: "Shipping Cost" },
    { field: "shippingType", headerName: "Shipping Type" },
    { field: "handlingTime", headerName: "DaysToShip" },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: actionRender,
    },
  ];
  function imageRender(params) {
    return (
      <>
        <img
          src={params.row.pictureURL}
          alt={`Picture for ${params.row.id}`}
          style={{ width: "80px", height: "80px" }}
        />
      </>
    );
  }

  function actionRender(params) {
    return (
      <>
        <Button
          variant="primary"
          onClick={() => TrackSingleItem(params.row.itemId)}
          className=""
        >
          Track Item
        </Button>
      </>
    );
  }
  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    if (sku && itemId) {
      axios
        .get(
          `https://fpt.sensegroup.org/WebAPI/Inventory/SearchCompetitor?SKU=${sku}&ListingId=${itemId}`
        )
        .then((res) => {
          setSearchPageData(res.data.searchRequest);
          setPrice(res.data.searchRequest.price);
          setTitle(res.data.searchRequest.title);
          setTableData(res.data.page);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error occurred:", error);
          notification.error({
            message: "Error",
            description: "Somthing went wrong",
            duration: 1,
          });
        });
    }
  };

  function search() {
    setLoading(true);
    if (sku && itemId && price && title) {
      axios
        .get(
          `https://fpt.sensegroup.org/WebAPI/Inventory/SearchCompetitor?SKU=${sku}&ListingId=${itemId}&Price=${price}&Title=${title}`
        )
        .then((res) => {
          setSearchPageData(res.data.searchRequest);
          setPrice(res.data.searchRequest.price);
          setTitle(res.data.searchRequest.title);
          setTableData(res.data.page);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error occurred:", error);
          notification.error({
            message: "Error",
            description: "Somthing went wrong",
            duration: 1,
          });
        });
    }
  
  }
  const tableSearch = (e) => {
    console.log(e.target.value);
  };

  const TrackSingleItem = (itemId) => {
    const track = {
      sku: sku,
      itemId: itemId,
    };
    axios
      .post(
        "https://fpt.sensegroup.org/WebAPI/Inventory/SearchCompetitor/TrackItem",
        track
      )
      .then((res) => {
        if (res.data === true) {
          getData();
          notification.success({
            message: "Success",
            description: "You have successfully tracked",
            duration: 1,
          });
        } else {
          notification.error({
            message: "Error",
            description: "Tracked failed",
            duration: 1,
          });
        }
      });
  };

  const trackedItems = () => {
    if (selectedRowIds.length !== 0) {
      const trackItems = {
        submitAction: "Delete",
        sku: sku,
        title: searchPageData.title,
        price: searchPageData.price,
        deleteSelection: selectedRowIds,
      };
      axios
        .post(
          "https://fpt.sensegroup.org/WebAPI/Inventory/SearchCompetitor/TrackItems",
          trackItems
        )
        .then((res) => {
          if (res.data === "Unable to add the selected item.") {
            notification.error({
              message: "Error",
              description: "Tracked failed",
              duration: 1,
            });
          } else {
            getData();
            notification.success({
              message: "Success",
              description: res.data,
              duration: 1,
            });
          }
        })
        .catch((error) => {
          console.error("Error occurred:", error);
          notification.error({
            message: "Error",
            description: "Somthing went wrong",
            duration: 1,
          });
        });
    } else {
      notification.warning({
        message: "Warning",
        description: "Please select minimum one checkbox",
        duration: 1,
      });
    }
  };

  const handlevalue = (e) => {
    console.log(e.target.value);
  };

  return (
    <Container fluid>
      <div className="page-top">
        <Row className="mt-4 " style={{ borderBottom: "1px solid #EEE" }}>
          <h5 className="mb-3">Search Result : {sku}</h5>
        </Row>
        <Row className="mt-4 mb-4">
          <Col md={1} className="img-col">
            <img src={searchPageData.imageURL} height="120" width="120"></img>
          </Col>
          <Col className="mt-3" md={4}>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)} 
            />
          </Col>
          <Col className="mt-3" md={2}>
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="text"
              placeholder="Price"
              value={price}
              onChange={(e) => setPrice(e.target.value)} 
            />
          </Col>
          <Col className="flex-column align-items-start mt-3">
            <Form.Label className="mb-2">Refine Search</Form.Label>
            <br />
            <Button variant="primary" onClick={search}>
              Search
            </Button>
          </Col>
        </Row>
      </div>
      {tableData !== null ? (
        <div
          className="page-notnull"
          style={{
            borderTop: "1px solid #EEE",
          }}
        >
          <div className="page-bottom mt-3">
            <Row className="d-flex justify-content-between">
              <Col className="d-flex  align-items-center mt-2">
                Search
                <Form.Control
                  type="text"
                  className="ms-3"
                  placeholder=""
                  style={{ width: "400px" }}
                  onChange={(e) => tableSearch(e)}
                />
              </Col>
              <Col className="d-flex mt-2 align-items-center justify-content-end ">
                <Button variant="primary" onClick={trackedItems}>
                  Track Items
                </Button>
              </Col>
            </Row>
            <Row className="mt-3" style={{ padding: "0px 12px 30px 12px" }}>
              <DataGrid
                style={{ padding: "0px", height: "400px" }}
                rows={tableData}
                columns={columns}
                disableRowSelectionOnClick
                checkboxSelection
                getRowId={(row) => row.itemId}
                getRowHeight={() => 100}
                rowSelectionModel={selectedRowIds}
                onRowSelectionModelChange={handleSelectionModelChange}
                loading={loading}
                slots={{
                  loadingOverlay: Loading,
                }}
              />
            </Row>
          </div>
        </div>
      ) : (
        <Row className="d-flex justify-content-center pt-5 pb-5 ps-3 fw-bold">
          No results found from eBay inventory, Please refine your search inputs
          and try again!
        </Row>
      )}
    </Container>
  );
}

export default Search;
