import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

function UserSetting() {
  return (
    <div className="custom-container container-fluid mt-4">
      <div className="top-content pb-2">
        <h5>User Settings</h5>
      </div>
      <Row className="mt-2">
        <Col xl={8} md={8}>
          <Form.Label>User Name</Form.Label>
          <Form.Control type="text" placeholder="" />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xl={8} md={8}>
          <Form.Label>Email</Form.Label>
          <Form.Control type="text" placeholder="" />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xl={8} md={8}>
          <Form.Label>New Password</Form.Label>
          <Form.Control type="text" placeholder="" />
          <div className="text-end mt-4">
            {" "}
            <Button>Save</Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default UserSetting;
