import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import { Stack, Pagination } from "@mui/material";
import edit1 from "../../../Images/edit1.png";
import edit2 from "../../../Images/edit2.png";
import find1 from "../../../Images/find1.png";
import find2 from "../../../Images/find2.png";
import Loading from "../../Assests/Loading";
import { Tooltip } from "antd";
import dollar1 from "../../../Images/dollar1.png";
import dollar2 from "../../../Images/dollar2.png";

function UnMappedListing() {
  const [selectedPageCount, setSelectedPageCount] = useState(100);
  const [unMappedList, setUnMappedList] = useState([]);
  const [productTotalCount, setProductTotlaCount] = useState("");
  const [filterCount, setFilterCount] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [filterShow, setFilterShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hoveredRowId1, setHoveredRowId1] = useState(null);
  const [hoveredRowId2, setHoveredRowId2] = useState(null);
  const [hoveredRowId3, setHoveredRowId3] = useState(null);

  const totalPageCount = Math.ceil(productTotalCount / selectedPageCount);
  const totalPageFilterCount = Math.ceil(filterCount / selectedPageCount);
  const startIndex = (currentPage - 1) * selectedPageCount + 1;
  const endIndex = Math.min(currentPage * selectedPageCount, productTotalCount);
  const endFilterIndex = Math.min(currentPage * selectedPageCount, filterCount);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDropdownSelect = (value) => {
    setSelectedPageCount(value);
  };

  const start = () => {
    UnMappedListing();
  };

  const CustomLinkCell = (params) => {
    return (
      <a
        href={`https://www.ebay.co.uk/itm/${params.row.itemId}`}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        {params.row.itemId}
      </a>
    );
  };

  const columns = [
    {
      field: "pictureURL",
      headerName: "Picture",
      width: 90,

      renderCell: renderImageCell,
    },
    { field: "sku", headerName: "SKU", flex: 0.4, minWidth: 170 },
    {
      field: "itemId",
      headerName: "ListingId",
      width: 120,
      renderCell: CustomLinkCell,
    },
    { field: "name", headerName: "Title", flex: 1, minWidth: 250 },
    { field: "price", headerName: "Price", width: 70 },
    { field: "cost", headerName: "Cost", width: 70 },
    { field: "minPrice", headerName: "Min Price", width: 70 },
    { field: "maxPrice", headerName: "Max Price", width: 70 },
    { field: "quantity", headerName: "Qty", width: 20 },
    {
      field: "tracked",
      headerName: "Tracked",
      width: 20,
      renderCell: (params) => (
        <Link
          to={`/tracked-items?sku=${params.row.sku}&itemId=${params.row.itemId}`}
          target="_blank"
          style={{ textDecoration: "none" }}
          rel="noopener noreferrer"
        >
          {params.row.tracked}
        </Link>
      ),
    },
    {
      field: "approved",
      headerName: "Appro ved",
      width: 20,
      renderCell: renderApprovedCell,
    },
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      renderCell: EditItemButton,
    },
  ];

  function renderImageCell(params) {
    return (
      <img
        src={params.row.pictureURL}
        alt={`Picture for ${params.row.id}`}
        style={{ width: "40px", height: "auto" }}
      />
    );
  }

  function renderApprovedCell(params) {
    return (
      <>
        {params.value === true ? (
          <div className="ms-1">
            <img src="images/true1.png" width="20px" height="20px" alt="true" />
          </div>
        ) : (
          <div className="ms-1">
            <img
              src="images/false1.png"
              width="20px"
              height="20px"
              alt="false"
            />
          </div>
        )}
      </>
    );
  }
  function EditItemButton(params) {
    return (
      <div className="d-flex ms-2 justify-content-center align-items-center">
       <Tooltip
          title={"Edit Price Range"}
          placement="top"
          overlayStyle={{
            backgroundColor: "white",
            color: "black",
            fontSize: "12x",
          }}
        >
          <Link to={`/edit-price?itemId=${params.row.itemId}`} target="_blank">
            {" "}
            <img
              src={hoveredRowId1 === params.row.itemId ? edit2 : edit1}
              onMouseEnter={() => setHoveredRowId1(params.row.itemId)}
              onMouseLeave={() => setHoveredRowId1(null)}
              className="me-2"
              width="23"
              height="23"
              alt=""
              onClick={() => handleEditItem(params.row)}
            ></img>{" "}
          </Link>
        </Tooltip>
        <Tooltip
          title={"Search competitor"}
          placement="top"
          overlayStyle={{
            backgroundColor: "white",
            color: "black",
            fontSize: "12x",
          }}
        >
          <Link
            to={`/search?itemId=${params.row.itemId}&sku=${params.row.sku}`}
            target="_blank"
          >
            <img
              src={hoveredRowId2 === params.row.itemId ? find2 : find1}
              onMouseEnter={() => setHoveredRowId2(params.row.itemId)}
              onMouseLeave={() => setHoveredRowId2(null)}
              className="me-2"
              width="23"
              height="23"
              alt=""
              onClick={() => handleFindCompetitors(params.row.id)}
            ></img>{" "}
          </Link>
        </Tooltip>
        <Tooltip
          title={"Reprice"}
          placement="top"
          overlayStyle={{
            backgroundColor: "white",
            color: "black",
            fontSize: "12x",
          }}
        >
          <img
            src={hoveredRowId3 === params.row.itemId ? dollar2 : dollar1}
            onMouseEnter={() => setHoveredRowId3(params.row.itemId)}
            onMouseLeave={() => setHoveredRowId3(null)}
            width="23"
            height="23"
            onClick={() => handleReprice(params.row)}
            alt=""
          ></img>
        </Tooltip>
      </div>
    );
  }
  function handleReprice(params) {
    const state = { price: params.price };
    const stateQuery = encodeURIComponent(JSON.stringify(state));
    const path = `/reprice?itemId=${params.itemId}&sku=${params.sku}&state=${stateQuery}`;
    window.open(path, "_blank");
  }

  function handleEditItem(itemId) {}

  function handleFindCompetitors(itemId) {}

  const handleInputChange = (event) => {
    
    const newValue = event.target.value;
    if (newValue === "") {
      setFilterShow(false);
    }
    setSearchValue(newValue);
    if(event.key === "Enter"){
      setLoading(true);
  

    const priceMonitorFilterData = {
      searchText: newValue,
      sortCol: "string",
      pageNbr: currentPage,
      pageSize: productTotalCount,
      direction: 0,
    };
    axios
      .post(
        "https://fpt.sensegroup.org/WebAPI/Inventory/UnMappedListing",
        priceMonitorFilterData
      )
      .then((res) => {
        setLoading(false);
        if (res.data.unMappedList === null) {
          setUnMappedList([]);
        } else {
          setUnMappedList(res.data.unMappedList);
        }
        setProductTotlaCount(res.data.totalCount);
        setFilterCount(res.data.filterCount);
        setFilterShow(true);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
    }
  };
  const priceMonitorData = {
    searchText: searchValue,
    sortCol: "string",
    pageNbr: currentPage,
    pageSize: selectedPageCount,
    direction: 0,
  };

  useEffect(() => {
    UnMappedListing();
  }, [currentPage, selectedPageCount]);

  const UnMappedListing = () => {
    setLoading(true);
    axios
      .post(
        "https://fpt.sensegroup.org/WebAPI/Inventory/UnMappedListing",
        priceMonitorData
      )
      .then((res) => {
        setLoading(false);
        if (res.data.unMappedList === null) {
          setUnMappedList([]);
        } else {
          setUnMappedList(res.data.unMappedList);
        }
        setProductTotlaCount(res.data.totalCount);
        setFilterCount(res.data.filterCount);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const exportAll = async () => {
    try {
      const downloadUrl = `https://fpt.sensegroup.org/WebAPI/ExportAll?name=UnMapped`;
      const response = await fetch(downloadUrl);

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      // Create a link and trigger the download
      const link = document.createElement("a");
      link.href = blobUrl;
      const currentDateTimeUTC = new Date()
        .toISOString()
        .replace(/[-:T]/g, "_")
        .replace(/\..+/, "")
        .replace(/_/g, " ")
        .substring(0, 19);

      link.download = `UnMappedListing_${currentDateTimeUTC}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div className="custom-container container-fluid mt-2">
      <div className="activelist-topbox d-flex justify-content-between">
        <h5 className="mt-2">UnMapped Listing</h5>
        <div className="activelist-buttons">
          <Button variant="primary" className="me-2 mt-2" onClick={exportAll}>
            Export All
          </Button>
          {/* <Button variant="primary" className="me-2" onClick={handleExport}>
            Export
          </Button> */}
          <Button variant="primary" className="me-2 mt-2" onClick={start}>
            Refresh
          </Button>
        </div>
      </div>
      <div className="activelist-tablebox">
        <div
          className="activelist-topbox d-flex justify-content-between align-items-center mt-3"
          style={{ alignItems: "flex-end" }}
        >
          <div>
            {!filterShow ? (
              <span>
                Showing {startIndex} to {endIndex} of {productTotalCount}{" "}
                results
              </span>
            ) : (
              <span>
                Showing {startIndex} to {endFilterIndex} of {filterCount}{" "}
                results (filtered from {productTotalCount} total rows)
              </span>
            )}
          </div>
          <div class=" d-flex align-items-center  me-2">
            <label for="" class="form-label me-2 mt-2">
              Search:
            </label>
            <input
              type="text"
              class="form-control"
              name=""
              id=""
              placeholder=""
              // value={searchValue}
              onKeyDown={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className="data mt-3">
        <DataGrid
          className=""
          style={{ height: "calc(76vh - 120px)", width: "100" }}
          rows={unMappedList}
          columns={columns}
          hideFooter={true}
          loading={loading}
          slots={{
            loadingOverlay: Loading,
          }}
          rowHeight={40}
        />
        <div className="table-footer">
          <Row className="mt-3" style={{ fontSize: "13px" }}>
            <Col md={3} sm={6}>
              <div className=" d-flex  align-item-center ">
                <p className="me-2 mb-0 mt-1"> Show</p>
                <DropdownButton
                  id="dropdown-basic"
                  title={
                    <>
                      {selectedPageCount}
                      <span className="icon ms-1" id="arrorw">
                        &#9663;
                      </span>{" "}
                    </>
                  }
                  onSelect={handleDropdownSelect}
                >
                  <Dropdown.Item eventKey="100">100</Dropdown.Item>
                  <Dropdown.Item eventKey="500">500</Dropdown.Item>
                  <Dropdown.Item eventKey="1000">1000</Dropdown.Item>
                </DropdownButton>
                <p className="ms-2 mb-0 mt-1"> Rows</p>
              </div>
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="paginaton ">
                {!filterShow ? (
                  <Stack spacing={2} className=" mb-3">
                    <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={totalPageCount}
                      page={currentPage}
                      onChange={handlePageChange}
                      style={{
                        "& .Mui-selected": {
                          backgroundColor: "red !important",
                        },
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack spacing={2} className=" mb-3">
                    <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={totalPageFilterCount}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </Stack>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default UnMappedListing;
