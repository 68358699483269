import { notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Container, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

function RePrice() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const itemId = searchParams.get("itemId");
  const sku = searchParams.get("sku");
  const [price, setPrice] = useState(null);
  const [newPrice, setNewPrice] = useState(0);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const stateString = searchParams.get("state");
    const state = JSON.parse(decodeURIComponent(stateString));
    const priceValue = state?.price;
    setPrice(priceValue);
  }, [location.search]);

  const handleInputChange = (e) => {
    setNewPrice(e.target.value);
  };
  const reprice = () => {
    const data = {
      sku: sku,
      listingId: itemId,
      price: Number(newPrice) ,
    };
   
    axios
    .post(`https://fpt.sensegroup.org/WebAPI/Inventory/RePrice`,data)
    .then((res)=>{
       if( res.data.startsWith("Price Revise Failed!")){
        notification.error({
            message: "Error",
             description: "Price Revise Failed",
             duration:1
             });
       }
       else if(res.data === "Please enter the valid new price!"){
        notification.warning({
            message: "Warning",
             description: "Please enter the valid new price",
             duration:1
             });
       }
       else if (res.data.endsWith("this SKU is Successfully Saved!")){
        notification.success({
            message: "Success",
             description: "You have successfully Updated",
             duration:1
             });
       }
       else{
        price =newPrice
        notification.error({
            message: "Error",
             description: "Somthing went wrong",
             duration:1
             });
       }
    })
    .catch((error) => {
      console.error("Error occurred:", error);
      notification.error({
        message: "Error",
        description: "Somthing went wrong",
        duration: 1
      });
    });
  };
  return (
    <>
      <Container>
        <Row className="mt-3 pb-3" style={{ borderBottom: "1px solid #EEE" }}>
          <h5>Current Price for SKU : {sku}</h5>
        </Row>
        <Row className="mt-3">
          <p className="fw-semibold">Current Price : {price}</p>
        </Row>
        <Row>
          <Form.Label>New price</Form.Label>
          <div className="" style={{ width: "500px" }}>
            <Form.Control
              type="text"
              placeholder="0"
              name="newPrice"
              value={newPrice || ""}
              onChange={handleInputChange}
            />
            
          </div>
        </Row>
        <div className="d-flex justify-content-end mt-3">
              <Button onClick={reprice}>Reprice</Button>
            </div>
      </Container>
    </>
  );
}

export default RePrice;
