import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "react-bootstrap";
import Loading from "../../Assests/Loading";
import { notification } from "antd";

function TrackedItems() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sku = searchParams.get("sku");
  const itemId = searchParams.get("itemId");
  const [trackedItems, setTrackecdItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    TrackedItems()
  }, []);

  const TrackedItems =()=>{
    setLoading(true);
    axios
      .post("https://fpt.sensegroup.org/WebAPI/TrackedItem/CompetitorList", {
        itemId: itemId,
        sku: sku,
      })
      .then((res) => {
        setTrackecdItems(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        notification.error({
          message: "Error",
          description: "Somthing went wrong",
          duration: 1
        });
      });
  }
  const CustomLinkCell = (params) => {
    return (
      <a
        href={`https://www.ebay.co.uk/itm/${params.row.itemId}`}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        {params.row.itemId}
      </a>
    );
  };

  const columns = [
    { field: "itemId", headerName: "ListingId", flex: 0.5, 
    renderCell: CustomLinkCell,},
    {
      field: "pictureURL",
      headerName: "Picture",
      width: 120,

      renderCell: renderImageCell,
    },
    { field: "title", headerName: "Title", flex: 1, },

    {
      field: "qtySold",
      headerName: "Quantity Sold",
      flex: 0.5,
    
    },
    {
      field: "qty",
      headerName: "Quantity Available",
      flex: 0.5,
    
    },
    { field: "seller", headerName: "Seller", flex: 0.5, },

    { field: "price", headerName: "Price", flex: 0.5, },

    {
      field: "UnTrack",
      headerName: "UnTrack",
      width: 200,
      renderCell: EditItemButton,
    },
  ];
  function renderImageCell(params) {
    return (
      <img
        src={params.row.pictureURL}
        alt={`Picture for ${params.row.id}`}
        style={{ width: "50px", height: "auto" }}
      />
    );
  }
  function EditItemButton(params) {
    return (
      <div className="d-flex ">
        <Button
          variant="primary"
          onClick={() => handleEditItem(params.row)}
          className="ms-5"
        >
          Untrack
        </Button>
      </div>
    );
  }

  const handleEditItem = (params) => {
    const untrack = {
      itemId: params.itemId,
      sku: params.sku,
    };
    axios({
      method: 'delete',
      url: 'https://fpt.sensegroup.org/WebAPI/Inventory/UnTrack',
      data: untrack,
    })
      .then((res) => {
        if(res.data === "Successfully deleted"){
          TrackedItems()
          notification.success({
            message: "Success",
            description: "You have successfully untracked",
            duration: 1,
          });
       
        }
        else{
          notification.error({
            message: "Error",
            description: res.data,
            duration: 1,
          });
        }
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        notification.error({
          message: "Error",
          description: "Somthing went wrong",
          duration: 1
        });
      });
  };

  return (
    <>
      <div className="custom-container container-fluid mt-4">
        <h5>Tracking Items</h5>

        <div className="table mt-3">
          <DataGrid
            //  style={{ width: "100%" }}
            style={{ height: "calc(80vh - 120px)", width: "100%" }}
            rows={trackedItems}
            disableRowSelectionOnClick
            columns={columns}
            hideFooter={true}
            getRowId={(row) => row.itemId}
            loading={loading}
            slots={{
              loadingOverlay: Loading,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default TrackedItems;
