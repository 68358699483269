import React, { useState } from "react";
import { Container, Row, Col, Dropdown, Button, Card } from "react-bootstrap";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import Loading from "../Assests/Loading";

function Main() {
  const [listingCount, setListingCount] = useState("");
  const [jobStatus, setJobStatus] = useState([]);
  const [recentSale, setRecentSale] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const jobStatusColumn = [
    { field: "name", headerName: "Name", flex: 0.6, minWidth: 200 },
    {
      field: "description",
      headerName: "Description",
      flex: 1.3,
      minWidth: 200,
    },
    { field: "type", headerName: "SchedulerType", flex: 0.4, minWidth: 120 },
    { field: "startedOn", headerName: "StartedOn", flex: 0.6, minWidth: 155 },
    { field: "endsOn", headerName: "CompletedOn", flex: 0.6, minWidth: 155 },
    {
      field: "recentSuccessfulCompletion",
      headerName: "Recent Successful Completion",
      flex: 0.6,
      minWidth: 155,
    },
    { field: "status", headerName: "Status", flex: 0.6, minWidth: 140 },
  ];

  const recentSaleColumn = [
    { field: "marketplace", headerName: "Channel", flex: 0.7, minWidth: 100 },
    { field: "startDate", headerName: "StartDate", flex: 0.7, minWidth: 160 },
    { field: "endDate", headerName: "EndDate", flex: 0.7, minWidth: 160 },
    { field: "totalAmount", headerName: "Amount", flex: 0.7, minWidth: 100 },
    { field: "currency", headerName: "Currency", flex: 0.7, minWidth: 100 },
  ];

  useEffect(() => {
    setLoading1(true);
    setLoading2(true);
    axios
      .get("https://fpt.sensegroup.org/WebAPI/DashBoard/ListingCount")
      .then((res) => {
        setListingCount(res.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    axios
      .post("https://fpt.sensegroup.org/WebAPI/DashBoard/RecentActivities")
      .then((res) => {
        const data2 = res.data.filter(report => 
          !report.name.includes("Amazon-Import Settlement Reports") &&
          !report.name.includes("Amazon Inventory Export") &&
          !report.name.includes("Stripe-Import Transactions")
        );
        setJobStatus(data2);
        setLoading1(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading1(false);
      });

    // axios
    //   .post("https://fpt.sensegroup.org/WebAPI/DashBoard/RecentSalesActivities")
    //   .then((res) => {
    //     setRecentSale(res.data);
    //     setLoading2(false);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data:", error);
    //     setLoading2(false);
    //   });
  }, []);

  return (
    <Container fluid className="custom-container ">
      <h5 className="mt-4"> Dashboard</h5>

      {/* <div className="content2-box d-flex align-items-center justify-content-center">
        <div className="content2 text-center">
          <Card style={{ width: "10rem" }}>
            <Card.Body>
              <Card.Text>{listingCount.active}</Card.Text>
              <Card.Text>Active</Card.Text>
              <Link to="/active-listing">
                {" "}
                <Button variant="primary">View Details</Button>
              </Link>
            </Card.Body>
          </Card>
        </div>
        <div className="content2 ms-3 text-center">
          <Card style={{ width: "10rem" }}>
            <Card.Body>
              <Card.Text>{listingCount.outOfStock}</Card.Text>
              <Card.Text>OutOfStock</Card.Text>
              <Link to="/outofstock-listing">
                {" "}
                <Button variant="primary">View Details</Button>
              </Link>
            </Card.Body>
          </Card>
        </div>
        <div className="content2 ms-3 text-center">
          <Card style={{ width: "10rem" }}>
            <Card.Body>
              <Card.Text>{listingCount.mapped}</Card.Text>
              <Card.Text>Mapped</Card.Text>
              <Link to="/mapped-listing">
                {" "}
                <Button variant="primary">View Details</Button>
              </Link>
            </Card.Body>
          </Card>
        </div>
        <div className="content2 ms-3 text-center">
          <Card style={{ width: "10rem" }}>
            <Card.Body>
              <Card.Text>{listingCount.unMapped}</Card.Text>
              <Card.Text>Unmapped</Card.Text>
              <Link to="/unmapped-listing">
                {" "}
                <Button variant="primary">View Details</Button>
              </Link>
            </Card.Body>
          </Card>
        </div>
        <div className="content2 ms-3 text-center">
          <Card style={{ width: "10rem" }}>
            <Card.Body>
              <Card.Text>{listingCount.reprice}</Card.Text>
              <Card.Text>Repricing</Card.Text>
              <Link to="/reprice-listing">
                {" "}
                <Button variant="primary">View Details</Button>
              </Link>
            </Card.Body>
          </Card>
        </div>
      </div> */}
      <Row className="text-center d-flex flex-row justify-content-center align-items-center ps-4 pe-4 pb-3" >
        <Col style={{ width: "10rem" }} md={3} sm={4} className="ms-3 mt-2 p-0" data-aos="zoom-in"    data-aos-duration="1000">
          <Card style={{ width: "10rem" }}>
            <Card.Body>
              <Card.Text>{listingCount.active}</Card.Text>
              <Card.Text>Active</Card.Text>
              <Link to="/active-listing">
                {" "}
                <Button variant="primary">View Details</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col style={{ width: "10rem" }} className="ms-3 mt-2 p-0" md={3} sm={4} data-aos="zoom-in"    data-aos-duration="1000">
        <Card style={{ width: "10rem" }}>
            <Card.Body>
              <Card.Text>{listingCount.outOfStock}</Card.Text>
              <Card.Text>OutOfStock</Card.Text>
              <Link to="/outofstock-listing">
                {" "}
                <Button variant="primary">View Details</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col style={{ width: "10rem" }} className="ms-3 mt-2 p-0" md={3} sm={4} data-aos="zoom-in"    data-aos-duration="1000">
        <Card style={{ width: "10rem" }}>
            <Card.Body>
              <Card.Text>{listingCount.mapped}</Card.Text>
              <Card.Text>Mapped</Card.Text>
              <Link to="/mapped-listing">
                {" "}
                <Button variant="primary">View Details</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col style={{ width: "10rem" }} className="ms-3 mt-2 p-0" md={3} sm={4} data-aos="zoom-in"    data-aos-duration="1000">
        <Card style={{ width: "10rem" }}>
            <Card.Body>
              <Card.Text>{listingCount.unMapped}</Card.Text>
              <Card.Text>Unmapped</Card.Text>
              <Link to="/unmapped-listing">
                {" "}
                <Button variant="primary">View Details</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col style={{ width: "10rem" }} className="ms-3 mt-2 p-0" md={3} sm={4} data-aos="zoom-in"    data-aos-duration="1000">
        <Card style={{ width: "10rem" }}>
            <Card.Body>
              <Card.Text>{listingCount.reprice}</Card.Text>
              <Card.Text>Repricing</Card.Text>
              <Link to="/reprice-listing">
                {" "}
                <Button variant="primary">View Details</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <h6 className="sub-title mt-2 mb-2">Job Status</h6>
      <div className="main-table mt-3 ">
        <DataGrid
          className=""
          // style={{ height: "258px", width: "100%" }}
          rows={jobStatus.map((row, index) => ({ ...row, id: index }))}
          columns={jobStatusColumn}
          hideFooter={true}
          loading={loading1}
          slots={{
            loadingOverlay: Loading,
          }}
          disableRowSelectionOnClick
          rowHeight={40}
        />
      </div>
      {/* <div className="sub-title mt-3 mb-3">
        Amazon Settlement Report History
      </div>
      <div className="main-table mt-2 pb-2">
        <DataGrid
          className=""
          style={{ height: "500px", width: "100%" }}
          rows={recentSale.map((row, index) => ({ ...row, id: index }))}
          columns={recentSaleColumn}
          hideFooter={true}
          loading={loading2}
          disableRowSelectionOnClick
          slots={{
            loadingOverlay: Loading,
          }}
          rowHeight={40}
        />
      </div> */}
    </Container>
  );
}

export default Main;
