
import React from "react";
import { Button, Nav, NavDropdown, Navbar } from "react-bootstrap";

function Header() {
  const useName = localStorage.getItem("username")

  const Logout =()=>{
    localStorage.clear()

  }
  return (
    <div className="header ">
      <Navbar
        expand="lg"
        className="bg-body-tertiary page-top-bar"
        style={{ height: "58px" }}
      >
        <form className="header-search"></form>

        <Nav
          className="menu-value justify-content-end mb-1 "
          style={{ marginRight: "" }}
        >
          <NavDropdown
            className="ms-4"
            title={
              <div className="profile-icon">
                <div className="profile-img">
                  <img src="images\profile.svg" alt="img" />
                  <i>
                    {" "}
                    <img src="images\active.svg" alt="img" />
                  </i>
                </div>
              {useName}
                <span>
                  <img src="images\arrow.svg" alt="drop icon" />
                </span>
              </div>
            }
            id="navbarScrollingDropdown"
          >
            <NavDropdown.Item >
              Profile
            </NavDropdown.Item>

            <NavDropdown.Item >
              Notifications
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/" onClick={Logout}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
        {/* </Navbar.Collapse> */}
      </Navbar>
    </div>
  );
}

export default Header;
