import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { Pagination, Stack } from "@mui/material";
import Loading from "../Assests/Loading";

function StatusHistory() {
  const [selectedPageCount, setSelectedPageCount] = useState(50);
  const [statusHistory, setStatusHistory] = useState([]);
  const [productTotalCount, setProductTotlaCount] = useState(100);
  const [filterCount, setFilterCount] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filtercurrentPage, setfilterCurrentPage] = useState(1);
  const [filterShow, setFilterShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [platform, setPlatform] = useState([]);
  const [filterPage, setFilterPage] = useState([]);

  const totalPageCount = Math.ceil(productTotalCount / selectedPageCount);
  const totalPageFilterCount = Math.ceil(filterCount / selectedPageCount);
  const startIndex = (currentPage - 1) * selectedPageCount + 1;
  const filterstartIndex = (filtercurrentPage - 1) * selectedPageCount + 1;
  const endIndex = Math.min(currentPage * selectedPageCount, productTotalCount);
  const endFilterIndex = Math.min(
    filtercurrentPage * selectedPageCount,
    filterCount
  );

  const handlePageChange = (event, value) => {
    if (filterShow === false) {
      setCurrentPage(value);
    } else {
      setLoading(true);
      setfilterCurrentPage(value);

      const itemsPerPageMap = {
        10: 10,
        50: 50,
        100: 100,
      };

      const itemsPerPage = itemsPerPageMap[selectedPageCount] || 10;
      const startIndex2 = (value - 1) * itemsPerPage;
      const endIndex2 = value * itemsPerPage;
      setStatusHistory(filterPage.slice(startIndex2, endIndex2));
      setLoading(false);
    }
  };

  const handleDropdownSelect = (value) => {
    setSelectedPageCount(value);
  };

  const refresh = () => {
    StatusHistory();
  };

  const columns = [
    { field: "batchId", headerName: "BatchId", flex: 1, minWidth: 110 },
    { field: "operation", headerName: "Operation", flex: 1, minWidth: 130 },
    { field: "status", headerName: "Status", flex: 1, minWidth: 130 },
    { field: "startedOn", headerName: "StartedOn", flex: 0.8, minWidth: 130 },
    { field: "finishedOn", headerName: "FinishedOn", flex: 0.8, minWidth: 130 },
    {
      field: "triggeredBy",
      headerName: "TriggeredBy",
      flex: 0.8,
      minWidth: 120,
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        return (
          <>
            {params.row.operation === "PriceUpdate" &&
              params.row.finishedOn !== null && (
                <Link
                  to={`/price-audit?batchId=${params.row.batchId}`}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noopener noreferrer"
                >
                  <div className="ms-4">View Result</div>{" "}
                </Link>
              )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    StatusHistory();
  }, [currentPage, selectedPageCount]);

  const StatusHistory = () => {
    setLoading(true);
    axios
      .get("https://fpt.sensegroup.org/WebAPI/Sync/StatusHistory")
      .then((res) => {
        if (res.data === null) {
          setStatusHistory([]);
        } else {
          const data = res.data;
          if (filterShow === false) {
            setPlatform(data);
            const itemsPerPageMap = {
              10: 10,
              50: 50,
              100: 100,
            };

            const itemsPerPage = itemsPerPageMap[selectedPageCount] || 10;
            const startIndex2 = (currentPage - 1) * itemsPerPage;
            const endIndex2 = currentPage * itemsPerPage;
            setStatusHistory(data.slice(startIndex2, endIndex2));
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value;
    if(e.key === "Enter"){

    

    if (searchQuery === "") {
      setSearchValue("");
      setFilterShow(false);
      const itemsPerPageMap = {
        10: 10,
        50: 50,
        100: 100,
      };

      const itemsPerPage = itemsPerPageMap[selectedPageCount] || 10;
      const startIndex2 = (currentPage - 1) * itemsPerPage;
      const endIndex2 = currentPage * itemsPerPage;
      setStatusHistory(platform.slice(startIndex2, endIndex2));
      setLoading(false);
    } else {
      setFilterShow(true);
      setSearchValue(searchQuery);

      const filteredRows = platform.filter((row) =>
        Object.values(row).some(
          (value) =>
            value !== null &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
      setFilterCount(filteredRows.length);
      const itemsPerPageMap = {
        10: 10,
        50: 50,
        100: 100,
      };
      const itemsPerPage = itemsPerPageMap[selectedPageCount] || 10;
      const startIndex2 = (filtercurrentPage - 1) * itemsPerPage;
      const endIndex2 = filtercurrentPage * itemsPerPage;
      setStatusHistory(filteredRows.slice(startIndex2, endIndex2));
      setFilterPage(filteredRows);
    }
  }
  };

  return (
    <div className="custom-container container-fluid mt-4">
      <div className="activelist-topbox d-flex justify-content-between">
        <h5> Sync Status History</h5>
        <div className="activelist-buttons">
          <Button variant="primary" className="me-2" onClick={refresh}>
            Refresh
          </Button>
        </div>
      </div>
      <div className="activelist-tablebox">
        <div
          className="activelist-topbox d-flex justify-content-between align-items-center mt-3"
          style={{ alignItems: "flex-end" }}
        >
          <div>
            {!filterShow ? (
              <span>
                Showing {startIndex} to {endIndex} of {productTotalCount}{" "}
                results
              </span>
            ) : (
              <span>
                Showing {filterstartIndex} to {endFilterIndex} of {filterCount}{" "}
                results (filtered from {productTotalCount} total rows)
              </span>
            )}
          </div>
          <div class=" d-flex align-items-center  me-2">
            <label for="" class="form-label me-2 mt-2">
              Search:
            </label>
            <input
              type="text"
              class="form-control"
              name=""
              id=""
              placeholder=""
              // value={searchValue}
              onKeyDown={handleSearch}
            />
          </div>
        </div>
      </div>
      <div className="data mt-3 ">
        <DataGrid
          className=""
          style={{ height: "calc(76vh - 120px)", width: "100%" }}
          rows={statusHistory.map((row, index) => ({ ...row, id: index }))}
          columns={columns}
          hideFooter={true}
          loading={loading}
          rowHeight={40}
          disableRowSelectionOnClick
          slots={{
            loadingOverlay: Loading,
          }}
        />
        <div className="table-footer">
          <Row className="mt-3" style={{ fontSize: "13px" }}>
            <Col md={3} sm={6}>
              <div className=" d-flex  align-item-center ">
                <p className="me-2 mb-0 mt-1"> Show</p>
                <DropdownButton
                  id="dropdown-basic"
                  title={
                    <>
                      {selectedPageCount}
                      <span className="icon ms-1" id="arrorw">
                        &#9663;
                      </span>{" "}
                    </>
                  }
                  onSelect={handleDropdownSelect}
                >
                  <Dropdown.Item eventKey="10">10</Dropdown.Item>
                  <Dropdown.Item eventKey="50">50</Dropdown.Item>
                  <Dropdown.Item eventKey="100">100</Dropdown.Item>
                </DropdownButton>
                <p className="ms-2 mb-0 mt-1"> Rows</p>
              </div>
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="paginaton ">
                {!filterShow ? (
                  <Stack spacing={2} className=" mb-3">
                    <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={totalPageCount}
                      page={currentPage}
                      onChange={handlePageChange}
                      style={{
                        "& .Mui-selected": {
                          backgroundColor: "red !important",
                        },
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack spacing={2} className=" mb-3">
                    <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={totalPageFilterCount}
                      page={filtercurrentPage}
                      onChange={handlePageChange}
                    />
                  </Stack>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default StatusHistory;
