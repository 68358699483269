import React from "react";
import Layout from "./Layout";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function SideBarToggle() {
  const [isOpen, setSidebarCollapsed] = useState(true);

  const toggleSidebar = () => {
    setSidebarCollapsed(!isOpen);
  };
  return (
    <>
      <Header />
      <FontAwesomeIcon
        icon={faBars}
        onClick={toggleSidebar}
        className="toggle mt-2"
        size="2x"
      />
      <div className={`layout-fixed ${isOpen ? "open" : ""}`}>
        <Layout />
      </div>
      <div
        className={`outlet-layout ${isOpen ? "shifted" : ""}`}
        style={{ marginLeft: isOpen ? "250px" : "0" }}
      >
        <Outlet />
      </div>
    </>
  );
}

export default SideBarToggle;
