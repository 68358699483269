import DashboardIcon from "@mui/icons-material/Dashboard";
import PaidIcon from "@mui/icons-material/Paid";
import DescriptionIcon from "@mui/icons-material/Description";
import DnsIcon from "@mui/icons-material/Dns";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SyncIcon from "@mui/icons-material/Sync";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SearchIcon from "@mui/icons-material/Search";

const menu = [
  {
    name: "Dashboard",
    to: "/dashboard",
    icon: <DashboardIcon fontSize="medium" />,
    active: "dashboard",
    hasSubMenu: false,
    submenuList: null,
  },

  {
    name: "Inventory Pricing",
    to: "#",
    icon: <PaidIcon fontSize="medium" />,
    active: "inventory-pricing",
    hasSubMenu: true,
    submenuList: [
      {
        name: "Active Listing",
        to: "/active-listing",
        active: "active-listing",
        parent: "inventory-pricing",
      },
      {
        name: "OutOfStock Listing",
        to: "/outofstock-listing",
        active: "outofstock-listing",
        parent: "inventory-pricing",
      },

      {
        name: "Mapped Listing",
        to: "/mapped-listing",
        active: "mapped-listing",
        parent: "inventory-pricing",
      },
      {
        name: "UnMapped Listing",
        to: "/unmapped-listing",
        active: "unmapped-listing",
        parent: "inventory-pricing",
      },
      {
        name: "Listing Expiry Protection",
        to: "/listing-expiry-protection",
        active: "listing-expiry-protection",
        parent: "inventory-pricing",
      },
      {
        name: "Reprice Listing",
        to: "/reprice-listing",
        activ: "reprice-listing",
        parent: "inventory-pricing",
      },
    ],
  },

  {
    name: "Inventory Reports",
    to: "#",
    icon: <DescriptionIcon fontSize="medium" />,
    active: "inventory-reports",
    hasSubMenu: true,
    submenuList: [
      {
        name: "Min Price Listing",
        to: "/minPrice-listing",
        active: "minPrice-listing",
        parent: "inventory-reports",
      },
      {
        name: "Max Price Listing",
        to: "/maxPrice-listing",
        active: "maxPrice-listing",
        parent: "inventory-reports",
      },
    ],
  },
  {
    name: "Inventory Policies",
    to: "#",
    icon: <DnsIcon fontSize="medium" />,
    active: "inventory-reports",
    hasSubMenu: true,
    submenuList: [
      {
        name: "Shipping Profiles",
        to: "/shipping-profiles",
        active: "shipping-profiles",
        parent: "inventory-policies",
      },
    ],
  },
  {
    name: "Import",
    to: "#",
    icon: <CloudUploadIcon fontSize="medium" />,
    active: "import",
    hasSubMenu: true,
    submenuList: [
      {
        name: "Price Range",
        to: "/pricerange",
        active: "pricerange",
        parent: "inventory-policies",
      },
      {
        name: "Competitor Listing",
        to: "/competitor-listing",
        active: "competitor-listing",
        parent: "inventory-policies",
      },
      {
        name: "Reprice Approval",
        to: "/reprice-approval",
        active: "reprice-approval",
        parent: "inventory-policies",
      },
    ],
  },
  {
    name: "Sales",
    to: "#",
    icon: <ShoppingCartIcon fontSize="medium" />,
    active: "sales",
    hasSubMenu: true,
    submenuList: [
      {
        name: "Ebay",
        to: "/ebay",
        active: "ebay",
        parent: "sales",
      },
      {
        name: "Amazon",
        to: "/amazon",
        active: "amazon",
        parent: "sales",
      },
      {
        name: "Magneto",
        to: "/magneto",
        active: "magneto",
        parent: "sales",
      },
      {
        name: "FTP Shedule",
        to: "/ftp-schedule",
        active: "ftp-schedule",
        parent: "sales",
      },
    ],
  },
  {
    name: "Sync",
    to: "#",
    icon: <SyncIcon fontSize="medium" />,
    active: "Sync",
    hasSubMenu: true,
    submenuList: [
      {
        name: "Manual Trigger",
        to: "/manual-trigger",
        active: "manual-trigger",
        parent: "Sync",
      },
      {
        name: "Status History",
        to: "/status-history",
        active: "status-hist0ry",
        parent: "Sync",
      },
    ],
  },
  {
    name: "Product Search",
    to: "#",
    icon: <SearchIcon fontSize="medium" />,
    active: "Product Search",
    hasSubMenu: true,
    submenuList: [
      {
        name: "Search Items",
        to: "/search-items",
        active: "search-items",
        parent: "Product Search",
      },
    ],
  },
  {
    name: "Settings",
    to: "#",
    icon: <ManageAccountsIcon fontSize="medium" />,
    active: "Settings",
    hasSubMenu: true,
    submenuList: [
      {
        name: "Account Settings",
        to: "/account-setting",
        active: "account-setting",
        parent: "Settings",
      },
      {
        name: "User Settings",
        to: "/user-setting",
        active: "user-setting",
        parent: "Settings",
      },
    ],
  },
];

export default menu;
