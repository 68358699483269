import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import { Pagination, Stack } from "@mui/material";
import Loading from "../Assests/Loading";

function ShippingProfiles() {
  const [selectedPageCount, setSelectedPageCount] = useState(100);
  const [shippingProfiles, setshippingProfiles] = useState([]);
  const [productTotalCount, setProductTotlaCount] = useState("");
  const [filterCount, setFilterCount] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterShow, setFilterShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const totalPageCount = Math.ceil(productTotalCount / selectedPageCount);
  const totalPageFilterCount = Math.ceil(filterCount / selectedPageCount);
  const startIndex = (currentPage - 1) * selectedPageCount + 1;
  const endIndex = Math.min(currentPage * selectedPageCount, productTotalCount);
  const endFilterIndex = Math.min(currentPage * selectedPageCount, filterCount);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDropdownSelect = (value) => {
    setSelectedPageCount(value);
  };

  const start = () => {
    ShippingProfiles();
  };

  const columns = [
    { field: "sku", headerName: "SKU", flex: 1 ,minWidth:200},
    { field: "shippingProfile", headerName: "Shipping Profile", flex: 1 ,minWidth:200 },
    { field: "paymentProfile", headerName: "Payment Profile", flex: 1,minWidth:200 },
    { field: "returnsProfile", headerName: "Returns Profile", flex: 0.8  ,minWidth:170},
    { field: "createdOn", headerName: "CreatedOn", flex: 0.8  ,minWidth:170},
    { field: "lastModifiedOn", headerName: "Last ModifiedOn", flex: 0.8  ,minWidth:170},
  ];

  const handleInputChange = (event) => {

    const newValue = event.target.value;
    if (newValue === "") {
      setFilterShow(false);
    }
    setSearchValue(newValue);

    if(event.key === "Enter"){
      setLoading(true)
    

    const priceMonitorFilterData = {
      searchText: newValue,
      sortCol: "string",
      pageNbr: currentPage,
      pageSize: productTotalCount,
      direction: 0,
    };
    axios
      .post(
        "https://fpt.sensegroup.org/WebAPI/InventoryPolicies/ShippingProfiles",
        priceMonitorFilterData
      )
      .then((res) => {
        setLoading(false)
        if (res.data.products === null) {
          setshippingProfiles([]);
        } else {
          setshippingProfiles(res.data.products);
        }
        setProductTotlaCount(res.data.totalCount);
        setFilterCount(res.data.filterCount);
        setFilterShow(true);
      })
      .catch((error) => {
        setLoading(false)
        console.error("Error fetching data:", error);
      });
    }
  };
  const priceMonitorData = {
    searchText: searchValue,
    sortCol: "string",
    pageNbr: currentPage,
    pageSize: selectedPageCount,
    direction: 0,
  };

  useEffect(() => {
    ShippingProfiles();
  }, [currentPage, selectedPageCount]);


  const ShippingProfiles = () => {
    setLoading(true);
    axios
      .post(
        "https://fpt.sensegroup.org/WebAPI/InventoryPolicies/ShippingProfiles",
        priceMonitorData
      )
      .then((res) => {
        setLoading(false);
        if (res.data.products === null) {
          setshippingProfiles([]);
        } else {
          setshippingProfiles(res.data.products);
        }
        setProductTotlaCount(res.data.totalCount);
        setFilterCount(res.data.filterCount);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const exportAll = async () => {
    try {
      const downloadUrl = `https://fpt.sensegroup.org/WebAPI/ExportAll?name=ShippingProfiles`;
      const response = await fetch(downloadUrl);

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      // Create a link and trigger the download
      const link = document.createElement("a");
      link.href = blobUrl;
      const currentDateTimeUTC = new Date()
        .toISOString()
        .replace(/[-:T]/g, "_")
        .replace(/\..+/, "")
        .replace(/_/g, " ")
        .substring(0, 19);

      link.download = `ShippingProfiles_${currentDateTimeUTC}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
 

  return (
    <div className="custom-container container-fluid mt-2">
      <div className="activelist-topbox d-flex justify-content-between">
        <h5 className="mt-2">Shipping Profiles</h5>
        <div className="activelist-buttons">
          <Button variant="primary" className="me-2 mt-2" onClick={exportAll}>
            Export All
          </Button>
          {/* <Button variant="primary" className="me-2" onClick={handleExport}>
            Export
          </Button> */}
          <Button variant="primary" className="me-2 mt-2" onClick={start}>
            Refresh
          </Button>
        </div>
      </div>
      <div className="activelist-tablebox">
        <div
          className="activelist-topbox d-flex justify-content-between align-items-center mt-3"
          style={{ alignItems: "flex-end" }}
        >
          <div>
            {!filterShow ? (
              <span>
                Showing {startIndex} to {endIndex} of {productTotalCount}{" "}
                results
              </span>
            ) : (
              <span>
                Showing {startIndex} to {endFilterIndex} of {filterCount}{" "}
                results (filtered from {productTotalCount} total rows)
              </span>
            )}
          </div>
          <div class=" d-flex align-items-center  me-2">
            <label for="" class="form-label me-2 mt-2">
              Search:
            </label>
            <input
              type="text"
              class="form-control"
              name=""
              id=""
              placeholder=""
              // value={searchValue}
              onKeyDown={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className="table mt-3 ">
        <DataGrid
          className=""
          style={{ height: "calc(76vh - 120px)", width: "100%" }}
          rows={shippingProfiles}
          columns={columns}
          hideFooter={true}
          loading={loading}
          disableRowSelectionOnClick
          rowHeight={40}
          slots={{
            loadingOverlay: Loading,
          }}
        />
         <div className="table-footer">
          <Row className="mt-3" style={{fontSize:"13px"}}>
            <Col  md={3} sm={6}> 
              <div className=" d-flex  align-item-center ">
                <p className="me-2 mb-0 mt-1"> Show</p>
                <DropdownButton
                  id="dropdown-basic"
                  title={
                    <>
                      {selectedPageCount}
                      <span className="icon ms-1" id="arrorw">
                        &#9663;
                      </span>{" "}
                    </>
                  }
                  onSelect={handleDropdownSelect}
                >
                     <Dropdown.Item eventKey="100">100</Dropdown.Item>
                  <Dropdown.Item eventKey="500">500</Dropdown.Item>
                  <Dropdown.Item eventKey="1000">1000</Dropdown.Item>
                </DropdownButton>
                <p className="ms-2 mb-0 mt-1"> Rows</p>
              </div>
            </Col>
            <Col   className="d-flex justify-content-end">
              <div className="paginaton ">
              {!filterShow ? (
            <Stack spacing={2} className=" mb-3">
              <Pagination
                variant="outlined"
                shape="rounded"
                count={totalPageCount}
                page={currentPage}
                onChange={handlePageChange}
                style={{
                  "& .Mui-selected": { backgroundColor: "red !important" },
                }}
              />
            </Stack>
          ) : (
            <Stack spacing={2} className=" mb-3">
              <Pagination
                variant="outlined"
                shape="rounded"
                count={totalPageFilterCount}
                page={currentPage}
                onChange={handlePageChange}
              />
            </Stack>
          )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ShippingProfiles;
