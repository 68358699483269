import React from "react";
import { Button, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

function ManualTrigger() {
  const navigate = useNavigate();
  const statusHistory = () => {
    navigate("/status-history");
  };
  return (
    <div className="custom-container container-fluid mt-4">
      <div className="top-content pb-2">
        <h5>Sync Marketplaces Data</h5>
      </div>
      <Row>
        <div className="ebay-content mt-3 ">
          <div className="ebay-title mb-3">
            <h6>
              {" "}
              <ShoppingCartIcon fontSize="medium" />
              eBay
            </h6>
          </div>
          <div className="table">
            <Table style={{ width: "100%" }}>
              <thead>
                <th style={{ width: "25%" }}>Action</th>
                <th>Description</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Button
                      variant="primary"
                      style={{ width: "100%" }}
                      onClick={statusHistory}
                    >
                      Import Listing
                    </Button>
                  </td>
                  <td>
                    Sync active eBay listings from the eBay (UK) to Price
                    Monitor Tool
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button
                      variant="primary"
                      style={{ width: "100%" }}
                      onClick={statusHistory}
                    >
                      Revise Prices
                    </Button>
                  </td>
                  <td>
                    Update prices on eBay for listings set to ‘Reprice’ against
                    competitors
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button
                      variant="primary"
                      style={{ width: "100%" }}
                      onClick={statusHistory}
                    >
                      Revise Stock Levels
                    </Button>
                  </td>
                  <td>
                    Import the latest inventory file from FTP location and
                    update eBay stock levels
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button
                      variant="primary"
                      style={{ width: "100%" }}
                      onClick={statusHistory}
                    >
                      Revise Shipping Profiles
                    </Button>
                  </td>
                  <td>
                    Import the latest ‘Shipping Profile’ file from FTP location
                    and update eBay shipping settings
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button
                      variant="primary"
                      style={{ width: "100%" }}
                      onClick={statusHistory}
                    >
                      ImportSales Records
                    </Button>
                  </td>
                  <td>Download all sales records for the last 24 Hrs</td>
                </tr>
                <tr>
                  <td>
                    <Button
                      variant="primary"
                      style={{ width: "100%" }}
                      onClick={statusHistory}
                    >
                      Revise Stock to active for Short Timespan
                    </Button>
                  </td>
                  <td>
                    Revise items which are Out-Of-Stock for the past one week
                    continuously, to avoid automatic end by eBay. Please trigger
                    this action when while no/less traffic to avoid orders
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button
                      variant="primary"
                      style={{ width: "100%" }}
                      onClick={statusHistory}
                    >
                      Price Range
                    </Button>
                  </td>
                  <td>
                    Import the Min, Max & Cost of SKUs from the FTP location in
                    to data source
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    <Button
                      variant="primary"
                      style={{ width: "100%" }}
                      onClick={statusHistory}
                    >
                      Amazon Inventory Export
                    </Button>
                  </td>
                  <td>Export amazon active inventory to list to FTP server</td>
                </tr> */}
                <tr>
                  <td>
                    <Button
                      variant="primary"
                      style={{ width: "100%" }}
                      onClick={statusHistory}
                    >
                      Delivery Address Import
                    </Button>
                  </td>
                  <td>
                    Import Order Delivery Address from the FTP location in to
                    data source
                  </td>
                </tr>
                <tr>
                  <td>
                    <Button
                      variant="primary"
                      style={{ width: "100%" }}
                      onClick={statusHistory}
                    >
                      Product Search
                    </Button>
                  </td>
                  <td>Find the products based on search condition</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        {/* <div className="amazon-content mt-3 mb-3">
          <div className="amazon-title mb-3">
            <h6>
              {" "}
              <ShoppingCartIcon fontSize="medium" />
              Amazon
            </h6>
          </div>
          <div className="table ">
            <Table style={{ width: "100%" }}>
              <thead>
                <th style={{ width: "25%" }}>Action</th>
                <th>Description</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Button
                      variant="primary"
                      style={{ width: "100%" }}
                      onClick={statusHistory}
                    >
                      Import Settlement Reports
                    </Button>
                  </td>
                  <td>
                    Retrieve the latest settlement reports for all Amazon
                    Marketplaces
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="stripe-content mt-3 mb-3">
          <div className="stripe-title mb-3">
            <h6>
              {" "}
              <ShoppingCartIcon fontSize="medium" />
              Stripe
            </h6>
          </div>
          <div className="table ">
            <Table style={{ width: "100%" }}>
              <thead>
                <th style={{ width: "25%" }}>Action</th>
                <th>Description</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Button
                      variant="primary"
                      style={{ width: "100%" }}
                      onClick={statusHistory}
                    >
                      Import Settlement Reports
                    </Button>
                  </td>
                  <td className="pt-3">
                    Retrieve the latest transaction report
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div> */}
      </Row>
    </div>
  );
}

export default ManualTrigger;
