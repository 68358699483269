import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";
import { Pagination, Stack } from "@mui/material";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Loading from "../Assests/Loading";

function ResultPage() {
  const [selectedPageCount, setSelectedPageCount] = useState(50);
  const [priceAudit, setPriceAudit] = useState([]);
  const [productTotalCount, setProductTotlaCount] = useState(100);
  const [filterCount, setFilterCount] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filtercurrentPage, setfilterCurrentPage] = useState(1);
  const [filterShow, setFilterShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [platform, setPlatform] = useState([]);
  const [filterPage, setFilterPage] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const batchId = searchParams.get("batchId");
  console.log(batchId);
  const totalPageCount = Math.ceil(productTotalCount / selectedPageCount);
  const totalPageFilterCount = Math.ceil(filterCount / selectedPageCount);
  const startIndex = (currentPage - 1) * selectedPageCount + 1;
  const filterstartIndex = (filtercurrentPage - 1) * selectedPageCount + 1;
  const endIndex = Math.min(currentPage * selectedPageCount, productTotalCount);
  const endFilterIndex = Math.min(
    filtercurrentPage * selectedPageCount,
    filterCount
  );

  const handlePageChange = (event, value) => {
    if (filterShow === false) {
      setCurrentPage(value);
    } else {
      setLoading(true);
      setfilterCurrentPage(value);

      const itemsPerPageMap = {
        10: 10,
        50: 50,
        100: 100,
      };

      const itemsPerPage = itemsPerPageMap[selectedPageCount] || 10;
      const startIndex2 = (value - 1) * itemsPerPage;
      const endIndex2 = value * itemsPerPage;
      setPriceAudit(filterPage.slice(startIndex2, endIndex2));
      setLoading(false);
    }
  };

  const handleDropdownSelect = (value) => {
    setSelectedPageCount(value);
  };

  const columns = [
    { field: "batchId", headerName: "BatchId", flex: 1, minWidth: 120 },
    { field: "sku", headerName: "SKU", flex: 1, minWidth: 200 },
    {
      field: "itemId",
      headerName: "Competitor Listing ID(with lowest price)",
      flex: 1,
      minWidth: 170,
    },
    {
      field: "lowestPrice",
      headerName: "Competitor Lowest Price",
      flex: 0.8,
      minWidth: 120,
    },
    { field: "price", headerName: "Price to Revise", flex: 0.8, minWidth: 120 },
  ];

  useEffect(() => {
    PriceAudit();
  }, [currentPage, selectedPageCount]);

  const PriceAudit = () => {
    setLoading(true);
    axios
      .get(
        `https://fpt.sensegroup.org/WebAPI/Sync/PriceAudit?BatchId=${batchId}`
      )
      .then((res) => {
        if (res.data === null) {
          setPriceAudit([]);
        } else {
          const data = res.data;
          if (filterShow === false) {
            setPlatform(data);
            setProductTotlaCount(data.length);
            const itemsPerPageMap = {
              10: 10,
              50: 50,
              100: 100,
            };

            const itemsPerPage = itemsPerPageMap[selectedPageCount] || 10;
            const startIndex2 = (currentPage - 1) * itemsPerPage;
            const endIndex2 = currentPage * itemsPerPage;
            setPriceAudit(data.slice(startIndex2, endIndex2));
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value;

    if (searchQuery === "") {
      setSearchValue("");
      setFilterShow(false);
      const itemsPerPageMap = {
        10: 10,
        50: 50,
        100: 100,
      };

      const itemsPerPage = itemsPerPageMap[selectedPageCount] || 10;
      const startIndex2 = (currentPage - 1) * itemsPerPage;
      const endIndex2 = currentPage * itemsPerPage;
      setPriceAudit(platform.slice(startIndex2, endIndex2));
      setLoading(false);
    } else {
      setFilterShow(true);
      setSearchValue(searchQuery);

      const filteredRows = platform.filter((row) =>
        Object.values(row).some(
          (value) =>
            value !== null &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
      setFilterCount(filteredRows.length);
      const itemsPerPageMap = {
        10: 10,
        50: 50,
        100: 100,
      };
      const itemsPerPage = itemsPerPageMap[selectedPageCount] || 10;
      const startIndex2 = (filtercurrentPage - 1) * itemsPerPage;
      const endIndex2 = filtercurrentPage * itemsPerPage;
      setPriceAudit(filteredRows.slice(startIndex2, endIndex2));
      setFilterPage(filteredRows);
    }
  };
  const handleExport = () => {
    // Convert activeList data to Excel format
    const worksheet = XLSX.utils.json_to_sheet(platform);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Active List");

    // Save the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const currentDateTimeUTC = new Date()
      .toISOString()
      .replace(/[-:T]/g, "_")
      .replace(/\..+/, "")
      .replace(/_/g, " ")
      .substring(0, 19);
    FileSaver.saveAs(excelBlob, `priceAudit_${currentDateTimeUTC}.xlsx`);
  };

  return (
    <div className="custom-container container-fluid mt-4">
      <div className="activelist-topbox d-flex justify-content-between">
        <h5> Price Audit</h5>
        <div className="activelist-buttons">
          <Button variant="primary" className="me-2" onClick={handleExport}>
            Export All
          </Button>
        </div>
      </div>
      <div className="activelist-tablebox">
        <div
          className="activelist-topbox d-flex justify-content-between align-items-center mt-3"
          style={{ alignItems: "flex-end" }}
        >
          <div>
            {!filterShow ? (
              <span>
                Showing {startIndex} to {endIndex} of {productTotalCount}{" "}
                results
              </span>
            ) : (
              <span>
                Showing {filterstartIndex} to {endFilterIndex} of {filterCount}{" "}
                results (filtered from {productTotalCount} total rows)
              </span>
            )}
          </div>
          <div class=" d-flex align-items-center  me-2">
            <label for="" class="form-label me-2 mt-2">
              Search:
            </label>
            <input
              type="text"
              class="form-control"
              name=""
              id=""
              placeholder=""
              value={searchValue}
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>
      <div className="data mt-3 mb-5 ">
        <DataGrid
          className=""
          style={{ height: "calc(82vh - 120px)", width: "100%" }}
          rows={priceAudit.map((row, index) => ({ ...row, id: index }))}
          columns={columns}
          hideFooter={true}
          loading={loading}
          rowHeight={40}
          disableRowSelectionOnClick
          slots={{
            loadingOverlay: Loading,
          }}
        />
        <div className="table-footer">
          <Row className="mt-3" style={{ fontSize: "13px" }}>
            <Col md={3} sm={6}>
              <div className=" d-flex  align-item-center ">
                <p className="me-2 mb-0 mt-1"> Show</p>
                <DropdownButton
                  id="dropdown-basic"
                  title={
                    <>
                      {selectedPageCount}
                      <span className="icon ms-1" id="arrorw">
                        &#9663;
                      </span>{" "}
                    </>
                  }
                  onSelect={handleDropdownSelect}
                >
                  <Dropdown.Item eventKey="10">10</Dropdown.Item>
                  <Dropdown.Item eventKey="50">50</Dropdown.Item>
                  <Dropdown.Item eventKey="100">100</Dropdown.Item>
                </DropdownButton>
                <p className="ms-2 mb-0 mt-1"> Rows</p>
              </div>
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="paginaton ">
                {!filterShow ? (
                  <Stack spacing={2} className=" mb-3">
                    <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={totalPageCount}
                      page={currentPage}
                      onChange={handlePageChange}
                      style={{
                        "& .Mui-selected": {
                          backgroundColor: "red !important",
                        },
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack spacing={2} className=" mb-3">
                    <Pagination
                      variant="outlined"
                      shape="rounded"
                      count={totalPageFilterCount}
                      page={filtercurrentPage}
                      onChange={handlePageChange}
                    />
                  </Stack>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ResultPage;
